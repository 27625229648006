import { Vehicle } from "../types/Vehicle";
import { VehicleType } from "../types/VehicleType";

export const FH4: Array<Vehicle> = [
  {
    Year: 2017,
    Manufacturer: "Abarth",
    Model: "124 Spider",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 43500,
    Class: "C",
    PI: 577,
  },
  {
    Year: 2016,
    Manufacturer: "Abarth",
    Model: "695 Biposto",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 48000,
    Class: "B",
    PI: 607,
  },
  {
    Year: 1980,
    Manufacturer: "Abarth",
    Model: "Fiat 131",
    Type: VehicleType.ClassicRally,
    Boost: "",
    Rarity: "Rare",
    Price: 38000,
    Class: "D",
    PI: 449,
  },
  {
    Year: 1968,
    Manufacturer: "Abarth",
    Model: "595 esseesse",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 2017,
    Manufacturer: "Acura",
    Model: "NSX",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 170000,
    Class: "S1",
    PI: 850,
  },
  {
    Year: 2002,
    Manufacturer: "Acura",
    Model: "RSX Type-S",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 588,
  },
  {
    Year: 2001,
    Manufacturer: "Acura",
    Model: "Integra Type-R",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 596,
  },
  {
    Year: 2018,
    Manufacturer: "Alfa Romeo",
    Model: "Stelvio Quadrifoglio",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Common",
    Price: 80000,
    Class: "A",
    PI: 752,
  },
  {
    Year: 2017,
    Manufacturer: "Alfa Romeo",
    Model: "Giulia Quadrifoglio",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 120000,
    Class: "A",
    PI: 795,
  },
  {
    Year: 2016,
    Manufacturer: "Alfa Romeo",
    Model: "Giulia Quadrifoglio",
    Type: VehicleType.SuperSaloons,
    Boost: "Event Influence",
    Rarity: "Forza Edition",
    Price: 370000,
    Class: "S1",
    PI: 812,
  },
  {
    Year: 2014,
    Manufacturer: "Alfa Romeo",
    Model: "4C",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 74000,
    Class: "A",
    PI: 770,
  },
  {
    Year: 2007,
    Manufacturer: "Alfa Romeo",
    Model: "8C Competizione",
    Type: VehicleType.GTCars,
    Boost: "Credits",
    Rarity: "Forza Edition",
    Price: 550000,
    Class: "S1",
    PI: 855,
  },
  {
    Year: 2007,
    Manufacturer: "Alfa Romeo",
    Model: "8C Competizione",
    Type: VehicleType.GTCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "A",
    PI: 777,
  },
  {
    Year: 1992,
    Manufacturer: "Alfa Romeo",
    Model: "155 Q4",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "C",
    PI: 541,
  },
  {
    Year: 1968,
    Manufacturer: "Alfa Romeo",
    Model: "33 Stradale",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "A",
    PI: 716,
  },
  {
    Year: 1965,
    Manufacturer: "Alfa Romeo",
    Model: "Giulia TZ2",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 2500000,
    Class: "B",
    PI: 639,
  },
  {
    Year: 1965,
    Manufacturer: "Alfa Romeo",
    Model: "Giulia Sprint GTA Stradale",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Epic",
    Price: 300000,
    Class: "D",
    PI: 432,
  },
  {
    Year: 1934,
    Manufacturer: "Alfa Romeo",
    Model: "P3",
    Type: VehicleType.VintageRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "B",
    PI: 626,
  },
  {
    Year: 2017,
    Manufacturer: "Alpine",
    Model: "A110",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "A",
    PI: 740,
  },
  {
    Year: 2015,
    Manufacturer: "Alumi Craft",
    Model: "Class 10 Race Car",
    Type: VehicleType.OffroadBuggies,
    Boost: "Destruction",
    Rarity: "Forza Edition",
    Price: 350000,
    Class: "B",
    PI: 679,
  },
  {
    Year: 2015,
    Manufacturer: "Alumi Craft",
    Model: "Class 10 Race Car",
    Type: VehicleType.OffroadBuggies,
    Boost: "",
    Rarity: "Epic",
    Price: 100000,
    Class: "B",
    PI: 673,
  },
  {
    Year: 1973,
    Manufacturer: "AMC",
    Model: "Gremlin X",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "D",
    PI: 394,
  },
  {
    Year: 1971,
    Manufacturer: "AMC",
    Model: "Javelin AMX",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "C",
    PI: 512,
  },
  {
    Year: 1970,
    Manufacturer: "AMC",
    Model: 'Rebel "The Machine"',
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "C",
    PI: 541,
  },
  {
    Year: 2554,
    Manufacturer: "AMG TD",
    Model: "M12S Warthog CST",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 850000,
    Class: "A",
    PI: 756,
  },
  {
    Year: 2018,
    Manufacturer: "Apollo",
    Model: "Intensa Emozione",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 1050000,
    Class: "S2",
    PI: 984,
  },
  {
    Year: 2016,
    Manufacturer: "Ariel",
    Model: "Nomad",
    Type: VehicleType.OffroadBuggies,
    Boost: "",
    Rarity: "Epic",
    Price: 93000,
    Class: "A",
    PI: 732,
  },
  {
    Year: 2013,
    Manufacturer: "Ariel",
    Model: "Atom 500 V8",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 108000,
    Class: "S2",
    PI: 970,
  },
  {
    Year: 2012,
    Manufacturer: "Ascari",
    Model: "KZ1R",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 240000,
    Class: "S1",
    PI: 866,
  },
  {
    Year: 2019,
    Manufacturer: "Aston Martin",
    Model: "DBS Superleggera",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S1",
    PI: 853,
  },
  {
    Year: 2018,
    Manufacturer: "Aston Martin",
    Model: "Vantage",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Epic",
    Price: 430000,
    Class: "S1",
    PI: 822,
  },
  {
    Year: 2017,
    Manufacturer: "Aston Martin",
    Model: "Vulcan AMR Pro",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 1500000,
    Class: "S2",
    PI: 954,
  },
  {
    Year: 2017,
    Manufacturer: "Aston Martin",
    Model: "DB11 PreOrder Car",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Epic",
    Price: 300000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 2017,
    Manufacturer: "Aston Martin",
    Model: "Vanquish Zagato Coupe",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S1",
    PI: 824,
  },
  {
    Year: 2017,
    Manufacturer: "Aston Martin",
    Model: "DB11",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Epic",
    Price: 300000,
    Class: "S1",
    PI: 810,
  },
  {
    Year: 2016,
    Manufacturer: "Aston Martin",
    Model: "Vulcan",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "Clean Skills",
    Rarity: "Forza Edition",
    Price: 1750000,
    Class: "S2",
    PI: 955,
  },
  {
    Year: 2016,
    Manufacturer: "Aston Martin",
    Model: "Vulcan",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 1500000,
    Class: "S2",
    PI: 954,
  },
  {
    Year: 2016,
    Manufacturer: "Aston Martin",
    Model: "Vantage GT12",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 400000,
    Class: "S1",
    PI: 846,
  },
  {
    Year: 2013,
    Manufacturer: "Aston Martin",
    Model: "V12 Vantage S",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Rare",
    Price: 240000,
    Class: "S1",
    PI: 814,
  },
  {
    Year: 2012,
    Manufacturer: "Aston Martin",
    Model: "Vanquish",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Epic",
    Price: 348000,
    Class: "S1",
    PI: 802,
  },
  {
    Year: 2010,
    Manufacturer: "Aston Martin",
    Model: "One-77",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Legendary",
    Price: 1400000,
    Class: "S1",
    PI: 863,
  },
  {
    Year: 1964,
    Manufacturer: "Aston Martin",
    Model: "DB5",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Epic",
    Price: 800000,
    Class: "C",
    PI: 534,
  },
  {
    Year: 1960,
    Manufacturer: "Aston Martin",
    Model: "DB4 GT Zagato",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "B",
    PI: 619,
  },
  {
    Year: 1958,
    Manufacturer: "Aston Martin",
    Model: "DBR1",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "B",
    PI: 688,
  },
  {
    Year: 2018,
    Manufacturer: "ATS",
    Model: "GT",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S1",
    PI: 894,
  },
  {
    Year: 2016,
    Manufacturer: "Audi",
    Model: "R8 V10 plus",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 242000,
    Class: "S1",
    PI: 856,
  },
  {
    Year: 2015,
    Manufacturer: "Audi",
    Model: "RS 6 Avant",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 150000,
    Class: "A",
    PI: 778,
  },
  {
    Year: 2015,
    Manufacturer: "Audi",
    Model: "TTS Coupe",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 52000,
    Class: "A",
    PI: 740,
  },
  {
    Year: 2015,
    Manufacturer: "Audi",
    Model: "S1",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 35000,
    Class: "B",
    PI: 679,
  },
  {
    Year: 2013,
    Manufacturer: "Audi",
    Model: "R8 Coupe V10 plus 5.2 FSI quattro",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 290000,
    Class: "S1",
    PI: 824,
  },
  {
    Year: 2013,
    Manufacturer: "Audi",
    Model: "RS 7 Sportback",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Legendary",
    Price: 225000,
    Class: "A",
    PI: 761,
  },
  {
    Year: 2013,
    Manufacturer: "Audi",
    Model: "RS 4 Avant",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 83000,
    Class: "A",
    PI: 750,
  },
  {
    Year: 2011,
    Manufacturer: "Audi",
    Model: "RS 5 Coupe",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 88000,
    Class: "A",
    PI: 750,
  },
  {
    Year: 2011,
    Manufacturer: "Audi",
    Model: "RS 3 Sportback",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 42000,
    Class: "B",
    PI: 699,
  },
  {
    Year: 2010,
    Manufacturer: "Audi",
    Model: "TT RS Coupe",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 66000,
    Class: "A",
    PI: 722,
  },
  {
    Year: 2009,
    Manufacturer: "Audi",
    Model: "RS 6",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 155000,
    Class: "A",
    PI: 743,
  },
  {
    Year: 2006,
    Manufacturer: "Audi",
    Model: "RS 4",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 53000,
    Class: "A",
    PI: 729,
  },
  {
    Year: 2003,
    Manufacturer: "Audi",
    Model: "RS 6",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 105000,
    Class: "A",
    PI: 710,
  },
  {
    Year: 2001,
    Manufacturer: "Audi",
    Model: "RS 4 Avant",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 94000,
    Class: "B",
    PI: 695,
  },
  {
    Year: 1995,
    Manufacturer: "Audi",
    Model: "RS 2 Avant",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "B",
    PI: 616,
  },
  {
    Year: 1986,
    Manufacturer: "Audi",
    Model: "#2 Audi Sport quattro S1",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Epic",
    Price: 375000,
    Class: "S1",
    PI: 850,
  },
  {
    Year: 1983,
    Manufacturer: "Audi",
    Model: "Sport quattro",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Legendary",
    Price: 175000,
    Class: "B",
    PI: 621,
  },
  {
    Year: 1964,
    Manufacturer: "Austin",
    Model: "FX4 Taxi",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1924,
    Manufacturer: "Austin",
    Model: "Seven",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1965,
    Manufacturer: "Austin-Healey",
    Model: "3000 MkIII",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "D",
    PI: 326,
  },
  {
    Year: 1958,
    Manufacturer: "Austin-Healey",
    Model: "Sprite MkI",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1939,
    Manufacturer: "Auto Union",
    Model: "Type D",
    Type: VehicleType.VintageRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "B",
    PI: 692,
  },
  {
    Year: 2014,
    Manufacturer: "BAC",
    Model: "Mono",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 160000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 2017,
    Manufacturer: "Bentley",
    Model: "Continental Supersports",
    Type: VehicleType.GTCars,
    Boost: "",
    Rarity: "Rare",
    Price: 200000,
    Class: "A",
    PI: 798,
  },
  {
    Year: 2016,
    Manufacturer: "Bentley",
    Model: "Bentayga",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Epic",
    Price: 180000,
    Class: "A",
    PI: 758,
  },
  {
    Year: 2013,
    Manufacturer: "Bentley",
    Model: "Continental GT Speed",
    Type: VehicleType.TrackToys,
    Boost: "Clean Skills",
    Rarity: "Forza Edition",
    Price: 492000,
    Class: "S1",
    PI: 835,
  },
  {
    Year: 2013,
    Manufacturer: "Bentley",
    Model: "Continental GT Speed",
    Type: VehicleType.GTCars,
    Boost: "",
    Rarity: "Rare",
    Price: 242000,
    Class: "A",
    PI: 774,
  },
  {
    Year: 1991,
    Manufacturer: "Bentley",
    Model: "Turbo R",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "C",
    PI: 546,
  },
  {
    Year: 1931,
    Manufacturer: "Bentley",
    Model: "4-1/2 Liter Supercharged",
    Type: VehicleType.VintageRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "D",
    PI: 200,
  },
  {
    Year: 1931,
    Manufacturer: "Bentley",
    Model: "8-Liter",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 1500000,
    Class: "D",
    PI: 176,
  },
  {
    Year: 2019,
    Manufacturer: "BMW",
    Model: "Z4 Roadster",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "A",
    PI: 722,
  },
  {
    Year: 2018,
    Manufacturer: "BMW",
    Model: "M5",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 105000,
    Class: "S1",
    PI: 806,
  },
  {
    Year: 2018,
    Manufacturer: "BMW",
    Model: "i8 Roadster",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 150000,
    Class: "A",
    PI: 776,
  },
  {
    Year: 2016,
    Manufacturer: "BMW",
    Model: "M4 GTS",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 135000,
    Class: "S1",
    PI: 841,
  },
  {
    Year: 2016,
    Manufacturer: "BMW",
    Model: "M2 Coupe",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 69000,
    Class: "A",
    PI: 737,
  },
  {
    Year: 2015,
    Manufacturer: "BMW",
    Model: "i8",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 140000,
    Class: "A",
    PI: 785,
  },
  {
    Year: 2015,
    Manufacturer: "BMW",
    Model: "X6 M",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Epic",
    Price: 130000,
    Class: "A",
    PI: 727,
  },
  {
    Year: 2014,
    Manufacturer: "BMW",
    Model: "M4 Coupe",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 92000,
    Class: "A",
    PI: 800,
  },
  {
    Year: 2013,
    Manufacturer: "BMW",
    Model: "M6 Coupe",
    Type: VehicleType.GTCars,
    Boost: "Drift Skills",
    Rarity: "Forza Edition",
    Price: 370000,
    Class: "A",
    PI: 780,
  },
  {
    Year: 2013,
    Manufacturer: "BMW",
    Model: "M6 Coupe",
    Type: VehicleType.GTCars,
    Boost: "",
    Rarity: "Epic",
    Price: 120000,
    Class: "A",
    PI: 779,
  },
  {
    Year: 2012,
    Manufacturer: "BMW",
    Model: "M5",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 112000,
    Class: "A",
    PI: 790,
  },
  {
    Year: 2011,
    Manufacturer: "BMW",
    Model: "1 Series M Coupe",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 55000,
    Class: "A",
    PI: 731,
  },
  {
    Year: 2011,
    Manufacturer: "BMW",
    Model: "Z4 sDrive35is",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 55000,
    Class: "A",
    PI: 727,
  },
  {
    Year: 2011,
    Manufacturer: "BMW",
    Model: "X5 M",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Epic",
    Price: 100000,
    Class: "A",
    PI: 708,
  },
  {
    Year: 2010,
    Manufacturer: "BMW",
    Model: "M3 GTS",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S1",
    PI: 814,
  },
  {
    Year: 2009,
    Manufacturer: "BMW",
    Model: "M5",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 90000,
    Class: "A",
    PI: 758,
  },
  {
    Year: 2008,
    Manufacturer: "BMW",
    Model: "M3",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 48000,
    Class: "A",
    PI: 745,
  },
  {
    Year: 2008,
    Manufacturer: "BMW",
    Model: "Z4 M Coupe",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 82000,
    Class: "A",
    PI: 710,
  },
  {
    Year: 2005,
    Manufacturer: "BMW",
    Model: "M3",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Common",
    Price: 33000,
    Class: "A",
    PI: 706,
  },
  {
    Year: 2003,
    Manufacturer: "BMW",
    Model: "M5",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Common",
    Price: 30000,
    Class: "A",
    PI: 719,
  },
  {
    Year: 2002,
    Manufacturer: "BMW",
    Model: "M3-GTR",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 120000,
    Class: "A",
    PI: 765,
  },
  {
    Year: 2002,
    Manufacturer: "BMW",
    Model: "Z3 M Coupe",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 30000,
    Class: "A",
    PI: 713,
  },
  {
    Year: 1997,
    Manufacturer: "BMW",
    Model: "M3",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "B",
    PI: 694,
  },
  {
    Year: 1995,
    Manufacturer: "BMW",
    Model: "M5",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "B",
    PI: 634,
  },
  {
    Year: 1995,
    Manufacturer: "BMW",
    Model: "850CSi",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "",
    Price: 250000,
    Class: "B",
    PI: 612,
  },
  {
    Year: 1991,
    Manufacturer: "BMW",
    Model: "M3",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "C",
    PI: 586,
  },
  {
    Year: 1988,
    Manufacturer: "BMW",
    Model: "M5",
    Type: VehicleType.RetroSaloons,
    Boost: "Event Influence",
    Rarity: "Forza Edition",
    Price: 304000,
    Class: "B",
    PI: 601,
  },
  {
    Year: 1988,
    Manufacturer: "BMW",
    Model: "M5",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "C",
    PI: 594,
  },
  {
    Year: 1981,
    Manufacturer: "BMW",
    Model: "M1",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 585000,
    Class: "B",
    PI: 652,
  },
  {
    Year: 1973,
    Manufacturer: "BMW",
    Model: "2002 Turbo",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 26000,
    Class: "D",
    PI: 500,
  },
  {
    Year: 1957,
    Manufacturer: "BMW",
    Model: "Isetta 300 Export",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Rare",
    Price: 45000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 2012,
    Manufacturer: "Bowler",
    Model: "EXR S",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 2000000,
    Class: "A",
    PI: 760,
  },
  {
    Year: 2019,
    Manufacturer: "Bugatti",
    Model: "Divo",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "S2",
    PI: 958,
  },
  {
    Year: 2018,
    Manufacturer: "Bugatti",
    Model: "Chiron",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2400000,
    Class: "S2",
    PI: 938,
  },
  {
    Year: 2011,
    Manufacturer: "Bugatti",
    Model: "Veyron Super Sport",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2200000,
    Class: "S2",
    PI: 922,
  },
  {
    Year: 1992,
    Manufacturer: "Bugatti",
    Model: "EB110 Super Sport",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1700000,
    Class: "S1",
    PI: 829,
  },
  {
    Year: 1926,
    Manufacturer: "Bugatti",
    Model: "Type 35 C",
    Type: VehicleType.VintageRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "D",
    PI: 374,
  },
  {
    Year: 1987,
    Manufacturer: "Buick",
    Model: "Regal GNX",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 130000,
    Class: "C",
    PI: 567,
  },
  {
    Year: 1970,
    Manufacturer: "Buick",
    Model: "GSX",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "C",
    PI: 572,
  },
  {
    Year: 2016,
    Manufacturer: "Cadillac",
    Model: "CTS-V Sedan",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 80000,
    Class: "A",
    PI: 785,
  },
  {
    Year: 2016,
    Manufacturer: "Cadillac",
    Model: "ATS-V",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 65000,
    Class: "A",
    PI: 737,
  },
  {
    Year: 2013,
    Manufacturer: "Cadillac",
    Model: "XTS Limousine",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 48500,
    Class: "C",
    PI: 534,
  },
  {
    Year: 2012,
    Manufacturer: "Cadillac",
    Model: "Escalade ESV",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "C",
    PI: 564,
  },
  {
    Year: 1959,
    Manufacturer: "Cadillac",
    Model: "Eldorado Biarritz Convertible",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Common",
    Price: 60000,
    Class: "D",
    PI: 400,
  },
  {
    Year: 2018,
    Manufacturer: "Can-Am",
    Model: "Maverick X RS Turbo R",
    Type: VehicleType.OffroadBuggies,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "B",
    PI: 692,
  },
  {
    Year: 2013,
    Manufacturer: "Caterham",
    Model: "Superlight R500",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 82000,
    Class: "S1",
    PI: 804,
  },
  {
    Year: 2013,
    Manufacturer: "Caterham",
    Model: "Superlight R500",
    Type: VehicleType.TrackToys,
    Boost: "Drift Skills",
    Rarity: "Forza Edition",
    Price: 332000,
    Class: "S1",
    PI: 804,
  },
  {
    Year: 2020,
    Manufacturer: "Chevrolet",
    Model: "Corvette Stingray Coupe",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S1",
    PI: 830,
  },
  {
    Year: 2019,
    Manufacturer: "Chevrolet",
    Model: "Corvette ZR1",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 255000,
    Class: "S1",
    PI: 895,
  },
  {
    Year: 2018,
    Manufacturer: "Chevrolet",
    Model: "Silverado 1500 DeBerti Design Drift Truck",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 300000,
    Class: "S1",
    PI: 849,
  },
  {
    Year: 2018,
    Manufacturer: "Chevrolet",
    Model: "Camaro ZL1 1LE",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 105000,
    Class: "S1",
    PI: 849,
  },
  {
    Year: 2017,
    Manufacturer: "Chevrolet",
    Model: "Camaro ZL1 Preorder Car",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 60000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 2017,
    Manufacturer: "Chevrolet",
    Model: "Camaro ZL1",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 60000,
    Class: "S1",
    PI: 848,
  },
  {
    Year: 2017,
    Manufacturer: "Chevrolet",
    Model: "Colorado ZR2",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Common",
    Price: 46000,
    Class: "C",
    PI: 598,
  },
  {
    Year: 2015,
    Manufacturer: "Chevrolet",
    Model: "Corvette Z06",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 110000,
    Class: "S1",
    PI: 871,
  },
  {
    Year: 2015,
    Manufacturer: "Chevrolet",
    Model: "Camaro Z/28",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Common",
    Price: 86000,
    Class: "S1",
    PI: 818,
  },
  {
    Year: 2009,
    Manufacturer: "Chevrolet",
    Model: "Corvette ZR1",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 125000,
    Class: "S1",
    PI: 824,
  },
  {
    Year: 2002,
    Manufacturer: "Chevrolet",
    Model: "Corvette Z06",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "A",
    PI: 748,
  },
  {
    Year: 1996,
    Manufacturer: "Chevrolet",
    Model: "Impala Super Sport",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "C",
    PI: 510,
  },
  {
    Year: 1995,
    Manufacturer: "Chevrolet",
    Model: "Corvette ZR-1",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 45000,
    Class: "A",
    PI: 741,
  },
  {
    Year: 1990,
    Manufacturer: "Chevrolet",
    Model: "Camaro IROC-Z",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: null,
    Class: "C",
    PI: 512,
  },
  {
    Year: 1988,
    Manufacturer: "Chevrolet",
    Model: "Monte Carlo Super Sport",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 415,
  },
  {
    Year: 1979,
    Manufacturer: "Chevrolet",
    Model: "Camaro Z28",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "D",
    PI: 460,
  },
  {
    Year: 1970,
    Manufacturer: "Chevrolet",
    Model: "Chevelle Super Sport Barrett-Jackson Edition",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 105000,
    Class: "A",
    PI: 757,
  },
  {
    Year: 1970,
    Manufacturer: "Chevrolet",
    Model: "Corvette ZR-1",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 310000,
    Class: "B",
    PI: 605,
  },
  {
    Year: 1970,
    Manufacturer: "Chevrolet",
    Model: "El Camino Super Sport 454",
    Type: VehicleType.VansAndUtility,
    Boost: "",
    Rarity: "Rare",
    Price: 65000,
    Class: "C",
    PI: 556,
  },
  {
    Year: 1970,
    Manufacturer: "Chevrolet",
    Model: "Camaro Z28",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 53000,
    Class: "C",
    PI: 547,
  },
  {
    Year: 1970,
    Manufacturer: "Chevrolet",
    Model: "Chevelle Super Sport 454",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 80000,
    Class: "C",
    PI: 542,
  },
  {
    Year: 1969,
    Manufacturer: "Chevrolet",
    Model: "Nova Super Sport 396",
    Type: VehicleType.ClassicMuscle,
    Boost: "Speed Skills",
    Rarity: "Forza Edition",
    Price: 297000,
    Class: "B",
    PI: 657,
  },
  {
    Year: 1969,
    Manufacturer: "Chevrolet",
    Model: "Camaro Super Sport Coupe",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 110000,
    Class: "C",
    PI: 566,
  },
  {
    Year: 1969,
    Manufacturer: "Chevrolet",
    Model: "Nova Super Sport 396",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 47000,
    Class: "C",
    PI: 552,
  },
  {
    Year: 1967,
    Manufacturer: "Chevrolet",
    Model: "Corvette Stingray 427",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 150000,
    Class: "B",
    PI: 621,
  },
  {
    Year: 1967,
    Manufacturer: "Chevrolet",
    Model: "Chevelle Super Sport 396",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "C",
    PI: 561,
  },
  {
    Year: 1966,
    Manufacturer: "Chevrolet",
    Model: "Nova Super Sport",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 70000,
    Class: "C",
    PI: 582,
  },
  {
    Year: 1964,
    Manufacturer: "Chevrolet",
    Model: "Impala Super Sport 409",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Rare",
    Price: 46000,
    Class: "C",
    PI: 527,
  },
  {
    Year: 1960,
    Manufacturer: "Chevrolet",
    Model: "Corvette",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 150000,
    Class: "C",
    PI: 521,
  },
  {
    Year: 1957,
    Manufacturer: "Chevrolet",
    Model: "Bel Air",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Rare",
    Price: 130000,
    Class: "D",
    PI: 444,
  },
  {
    Year: 1955,
    Manufacturer: "Chevrolet",
    Model: "150 Utility Sedan",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Rare",
    Price: 35000,
    Class: "D",
    PI: 286,
  },
  {
    Year: 1953,
    Manufacturer: "Chevrolet",
    Model: "Corvette",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 135000,
    Class: "D",
    PI: 347,
  },
  {
    Year: 1972,
    Manufacturer: "Chrysler",
    Model: "VH Valiant Charger R/T E49",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 60000,
    Class: "C",
    PI: 557,
  },
  {
    Year: 1970,
    Manufacturer: "Datsun",
    Model: "510",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 209,
  },
  {
    Year: 2018,
    Manufacturer: "Dodge",
    Model: "Challenger SRT Demon",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 150000,
    Class: "S1",
    PI: 823,
  },
  {
    Year: 2018,
    Manufacturer: "Dodge",
    Model: "Durango SRT",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Rare",
    Price: 70000,
    Class: "A",
    PI: 709,
  },
  {
    Year: 2016,
    Manufacturer: "Dodge",
    Model: "Viper ACR",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 150000,
    Class: "S1",
    PI: 893,
  },
  {
    Year: 2015,
    Manufacturer: "Dodge",
    Model: "Charger SRT Hellcat",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 80000,
    Class: "A",
    PI: 785,
  },
  {
    Year: 2015,
    Manufacturer: "Dodge",
    Model: "Challenger SRT Hellcat",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 75000,
    Class: "A",
    PI: 776,
  },
  {
    Year: 2013,
    Manufacturer: "Dodge",
    Model: "SRT Viper GTS",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 95000,
    Class: "S1",
    PI: 831,
  },
  {
    Year: 2008,
    Manufacturer: "Dodge",
    Model: "Viper SRT10 ACR",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 90000,
    Class: "S1",
    PI: 866,
  },
  {
    Year: 1999,
    Manufacturer: "Dodge",
    Model: "Viper GTS ACR",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 75000,
    Class: "A",
    PI: 732,
  },
  {
    Year: 1970,
    Manufacturer: "Dodge",
    Model: "Challenger R/T",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 210000,
    Class: "C",
    PI: 562,
  },
  {
    Year: 1969,
    Manufacturer: "Dodge",
    Model: "Charger Daytona HEMI",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 900000,
    Class: "C",
    PI: 595,
  },
  {
    Year: 1969,
    Manufacturer: "Dodge",
    Model: "Charger R/T",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 103000,
    Class: "C",
    PI: 558,
  },
  {
    Year: 1968,
    Manufacturer: "Dodge",
    Model: "Dart HEMI Super Stock",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 125000,
    Class: "B",
    PI: 626,
  },
  {
    Year: 2013,
    Manufacturer: "Donkervoort",
    Model: "D8 GTO",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 175000,
    Class: "S1",
    PI: 827,
  },
  {
    Year: 2011,
    Manufacturer: "DS Automobiles",
    Model: "DS3 Racing",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 38000,
    Class: "B",
    PI: 624,
  },
  {
    Year: 2012,
    Manufacturer: "Eagle (U.K.)",
    Model: "Speedster",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 550000,
    Class: "A",
    PI: 740,
  },
  {
    Year: 2018,
    Manufacturer: "Exomotive",
    Model: "Exocet Off-Road",
    Type: VehicleType.OffroadBuggies,
    Boost: "",
    Rarity: "Epic",
    Price: 50000,
    Class: "A",
    PI: 729,
  },
  {
    Year: 2019,
    Manufacturer: "Ferrari",
    Model: "488 Pista",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "S2",
    PI: 912,
  },
  {
    Year: 2018,
    Manufacturer: "Ferrari",
    Model: "Portofino",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Epic",
    Price: 215000,
    Class: "S1",
    PI: 834,
  },
  {
    Year: 2017,
    Manufacturer: "Ferrari",
    Model: "812 Superfast",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Common",
    Price: 1400000,
    Class: "S1",
    PI: 897,
  },
  {
    Year: 2017,
    Manufacturer: "Ferrari",
    Model: "GTC4Lusso",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Legendary",
    Price: 430000,
    Class: "S1",
    PI: 840,
  },
  {
    Year: 2015,
    Manufacturer: "Ferrari",
    Model: "F12tdf",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "S2",
    PI: 901,
  },
  {
    Year: 2015,
    Manufacturer: "Ferrari",
    Model: "488 GTB",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 290000,
    Class: "S1",
    PI: 883,
  },
  {
    Year: 2014,
    Manufacturer: "Ferrari",
    Model: "FXX K",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 2700000,
    Class: "S2",
    PI: 989,
  },
  {
    Year: 2014,
    Manufacturer: "Ferrari",
    Model: "California T",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Rare",
    Price: 240000,
    Class: "S1",
    PI: 804,
  },
  {
    Year: 2013,
    Manufacturer: "Ferrari",
    Model: "LaFerrari",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1500000,
    Class: "S2",
    PI: 966,
  },
  {
    Year: 2013,
    Manufacturer: "Ferrari",
    Model: "458 Speciale",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 340000,
    Class: "S1",
    PI: 885,
  },
  {
    Year: 2012,
    Manufacturer: "Ferrari",
    Model: "599XX Evoluzione",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S2",
    PI: 979,
  },
  {
    Year: 2012,
    Manufacturer: "Ferrari",
    Model: "F12berlinetta",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Epic",
    Price: 380000,
    Class: "S1",
    PI: 868,
  },
  {
    Year: 2011,
    Manufacturer: "Ferrari",
    Model: "FF",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 255000,
    Class: "S1",
    PI: 815,
  },
  {
    Year: 2010,
    Manufacturer: "Ferrari",
    Model: "599XX",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 1000000,
    Class: "S2",
    PI: 937,
  },
  {
    Year: 2010,
    Manufacturer: "Ferrari",
    Model: "599 GTO",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Epic",
    Price: 690000,
    Class: "S1",
    PI: 861,
  },
  {
    Year: 2009,
    Manufacturer: "Ferrari",
    Model: "458 Italia",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 300000,
    Class: "S1",
    PI: 846,
  },
  {
    Year: 2007,
    Manufacturer: "Ferrari",
    Model: "430 Scuderia",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 300000,
    Class: "S1",
    PI: 834,
  },
  {
    Year: 2005,
    Manufacturer: "Ferrari",
    Model: "FXX",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Common",
    Price: 2500000,
    Class: "S2",
    PI: 961,
  },
  {
    Year: 2003,
    Manufacturer: "Ferrari",
    Model: "360 Challenge Stradale",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 200000,
    Class: "A",
    PI: 792,
  },
  {
    Year: 2002,
    Manufacturer: "Ferrari",
    Model: "Enzo Ferrari",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2800000,
    Class: "S1",
    PI: 874,
  },
  {
    Year: 2002,
    Manufacturer: "Ferrari",
    Model: "575M Maranello",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 140000,
    Class: "A",
    PI: 772,
  },
  {
    Year: 1996,
    Manufacturer: "Ferrari",
    Model: 'F50 GT "Welcome Pack"',
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 1200000,
    Class: "S2",
    PI: 998,
  },
  {
    Year: 1996,
    Manufacturer: "Ferrari",
    Model: "F50 GT",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 1200000,
    Class: "S2",
    PI: 992,
  },
  {
    Year: 1995,
    Manufacturer: "Ferrari",
    Model: "F50",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2000000,
    Class: "S1",
    PI: 815,
  },
  {
    Year: 1994,
    Manufacturer: "Ferrari",
    Model: "F355 Berlinetta",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 190000,
    Class: "A",
    PI: 737,
  },
  {
    Year: 1992,
    Manufacturer: "Ferrari",
    Model: "512 TR",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 270000,
    Class: "A",
    PI: 754,
  },
  {
    Year: 1989,
    Manufacturer: "Ferrari",
    Model: "F40 Competizione",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 3000000,
    Class: "S2",
    PI: 961,
  },
  {
    Year: 1987,
    Manufacturer: "Ferrari",
    Model: "F40",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1200000,
    Class: "S1",
    PI: 807,
  },
  {
    Year: 1984,
    Manufacturer: "Ferrari",
    Model: "288 GTO",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 3100000,
    Class: "A",
    PI: 768,
  },
  {
    Year: 1970,
    Manufacturer: "Ferrari",
    Model: "512 S",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "S1",
    PI: 872,
  },
  {
    Year: 1969,
    Manufacturer: "Ferrari",
    Model: "Dino 246 GT",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 425000,
    Class: "C",
    PI: 512,
  },
  {
    Year: 1968,
    Manufacturer: "Ferrari",
    Model: "365 GTB/4",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Epic",
    Price: 600000,
    Class: "B",
    PI: 641,
  },
  {
    Year: 1967,
    Manufacturer: "Ferrari",
    Model: "#24 Ferrari Spa 330 P4",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "A",
    PI: 799,
  },
  {
    Year: 1963,
    Manufacturer: "Ferrari",
    Model: "250LM",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "A",
    PI: 732,
  },
  {
    Year: 1962,
    Manufacturer: "Ferrari",
    Model: "250 GTO",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "B",
    PI: 679,
  },
  {
    Year: 1962,
    Manufacturer: "Ferrari",
    Model: "250 GT Berlinetta Lusso",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 2600000,
    Class: "C",
    PI: 568,
  },
  {
    Year: 1957,
    Manufacturer: "Ferrari",
    Model: "250 Testa Rossa",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "A",
    PI: 704,
  },
  {
    Year: 1957,
    Manufacturer: "Ferrari",
    Model: "250 California",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 8000000,
    Class: "C",
    PI: 540,
  },
  {
    Year: 1953,
    Manufacturer: "Ferrari",
    Model: "500 Mondial",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 1000000,
    Class: "C",
    PI: 544,
  },
  {
    Year: 1948,
    Manufacturer: "Ferrari",
    Model: "166MM Barchetta",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 6500000,
    Class: "C",
    PI: 554,
  },
  {
    Year: 1980,
    Manufacturer: "FIAT",
    Model: "124 Sport Spider",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 244,
  },
  {
    Year: 1975,
    Manufacturer: "FIAT",
    Model: "X1/9",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "D",
    PI: 127,
  },
  {
    Year: 1969,
    Manufacturer: "FIAT",
    Model: "Dino 2.4 Coupe",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "D",
    PI: 468,
  },
  {
    Year: 2020,
    Manufacturer: "Ford",
    Model: "Mustang Shelby GT500",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S1",
    PI: 863,
  },
  {
    Year: 2019,
    Manufacturer: "Ford",
    Model: "Ranger Raptor",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Epic",
    Price: 58000,
    Class: "D",
    PI: 498,
  },
  {
    Year: 2018,
    Manufacturer: "Ford",
    Model: "#25 Mustang RTR",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "S1",
    PI: 871,
  },
  {
    Year: 2018,
    Manufacturer: "Ford",
    Model: "#88 Mustang RTR",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "S1",
    PI: 871,
  },
  {
    Year: 2018,
    Manufacturer: "Ford",
    Model: "Mustang GT DeBerti Design",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "S1",
    PI: 835,
  },
  {
    Year: 2018,
    Manufacturer: "Ford",
    Model: "Mustang RTR Spec 5",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "A",
    PI: 780,
  },
  {
    Year: 2018,
    Manufacturer: "Ford",
    Model: "Mustang GT",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 40000,
    Class: "A",
    PI: 778,
  },
  {
    Year: 2018,
    Manufacturer: "Ford",
    Model: "F-150 Prerunner DeBerti Design Truck",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "A",
    PI: 750,
  },
  {
    Year: 2017,
    Manufacturer: "Ford",
    Model: 'GT "Welcome Pack"',
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 400000,
    Class: "S2",
    PI: 998,
  },
  {
    Year: 2017,
    Manufacturer: "Ford",
    Model: "GT",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 400000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 2017,
    Manufacturer: "Ford",
    Model: "#14 Rahal Letterman Lanigan Racing GRC Fiesta",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 880,
  },
  {
    Year: 2017,
    Manufacturer: "Ford",
    Model: "M-Sport Fiesta RS",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 819,
  },
  {
    Year: 2017,
    Manufacturer: "Ford",
    Model: "Focus RS Preorder Car",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 59000,
    Class: "A",
    PI: 800,
  },
  {
    Year: 2017,
    Manufacturer: "Ford",
    Model: "Focus RS",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 59000,
    Class: "A",
    PI: 718,
  },
  {
    Year: 2017,
    Manufacturer: "Ford",
    Model: "F-150 Raptor",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Rare",
    Price: 63000,
    Class: "B",
    PI: 627,
  },
  {
    Year: 2016,
    Manufacturer: "Ford",
    Model: "Shelby GT350R",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 75000,
    Class: "S1",
    PI: 840,
  },
  {
    Year: 2015,
    Manufacturer: "Ford",
    Model: "Falcon GT F 351",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 60000,
    Class: "A",
    PI: 739,
  },
  {
    Year: 2014,
    Manufacturer: "Ford",
    Model: "#11 Rockstar F-150 Trophy Truck",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "A",
    PI: 785,
  },
  {
    Year: 2014,
    Manufacturer: "Ford",
    Model: "FPV Limited Edition Pursuit Ute",
    Type: VehicleType.VansAndUtility,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "A",
    PI: 710,
  },
  {
    Year: 2014,
    Manufacturer: "Ford",
    Model: "Ranger T6 Rally Raid",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "A",
    PI: 703,
  },
  {
    Year: 2014,
    Manufacturer: "Ford",
    Model: "Fiesta ST",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "B",
    PI: 621,
  },
  {
    Year: 2013,
    Manufacturer: "Ford",
    Model: "Shelby GT500",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 115000,
    Class: "A",
    PI: 774,
  },
  {
    Year: 2011,
    Manufacturer: "Ford",
    Model: "Transit SuperSportVan",
    Type: VehicleType.VansAndUtility,
    Boost: "Speed Skills",
    Rarity: "Forza Edition",
    Price: 300000,
    Class: "B",
    PI: 695,
  },
  {
    Year: 2011,
    Manufacturer: "Ford",
    Model: "F-150 SVT Raptor",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Common",
    Price: 42000,
    Class: "C",
    PI: 564,
  },
  {
    Year: 2011,
    Manufacturer: "Ford",
    Model: "Transit SuperSportVan",
    Type: VehicleType.VansAndUtility,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "D",
    PI: 416,
  },
  {
    Year: 2010,
    Manufacturer: "Ford",
    Model: "Crown Victoria Police Interceptor",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 521,
  },
  {
    Year: 2009,
    Manufacturer: "Ford",
    Model: "Focus RS",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 25000,
    Class: "B",
    PI: 696,
  },
  {
    Year: 2005,
    Manufacturer: "Ford",
    Model: "GT",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 300000,
    Class: "S1",
    PI: 810,
  },
  {
    Year: 2003,
    Manufacturer: "Ford",
    Model: "Focus RS",
    Type: VehicleType.ModernRally,
    Boost: "",
    Rarity: "Common",
    Price: 30000,
    Class: "B",
    PI: 649,
  },
  {
    Year: 2000,
    Manufacturer: "Ford",
    Model: "SVT Cobra R",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 55000,
    Class: "B",
    PI: 682,
  },
  {
    Year: 1999,
    Manufacturer: "Ford",
    Model: "Racing Puma",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "C",
    PI: 549,
  },
  {
    Year: 1994,
    Manufacturer: "Ford",
    Model: "Supervan 3",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "S1",
    PI: 828,
  },
  {
    Year: 1993,
    Manufacturer: "Ford",
    Model: "SVT Cobra R",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 28000,
    Class: "C",
    PI: 533,
  },
  {
    Year: 1992,
    Manufacturer: "Ford",
    Model: "Escort RS Cosworth",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Rare",
    Price: 66000,
    Class: "C",
    PI: 566,
  },
  {
    Year: 1987,
    Manufacturer: "Ford",
    Model: "Sierra Cosworth RS500",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 66000,
    Class: "B",
    PI: 608,
  },
  {
    Year: 1986,
    Manufacturer: "Ford",
    Model: "Escort RS Turbo",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Legendary",
    Price: 25000,
    Class: "C",
    PI: 506,
  },
  {
    Year: 1985,
    Manufacturer: "Ford",
    Model: "RS200 Evolution",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 260000,
    Class: "S1",
    PI: 839,
  },
  {
    Year: 1981,
    Manufacturer: "Ford",
    Model: "Fiesta XR2",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 324,
  },
  {
    Year: 1977,
    Manufacturer: "Ford",
    Model: "Escort RS1800",
    Type: VehicleType.ClassicRally,
    Boost: "",
    Rarity: "Epic",
    Price: 88000,
    Class: "D",
    PI: 311,
  },
  {
    Year: 1977,
    Manufacturer: "Ford",
    Model: "#5 Escort RS1800 MkII",
    Type: VehicleType.ClassicRally,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "A",
    PI: 729,
  },
  {
    Year: 1975,
    Manufacturer: "Ford",
    Model: "Bronco Barrett-Jackson Edition",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Epic",
    Price: 105000,
    Class: "B",
    PI: 630,
  },
  {
    Year: 1975,
    Manufacturer: "Ford",
    Model: "Bronco",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Common",
    Price: 38000,
    Class: "D",
    PI: 379,
  },
  {
    Year: 1973,
    Manufacturer: "Ford",
    Model: "Capri RS3100",
    Type: VehicleType.RallyMonsters,
    Boost: "Skills",
    Rarity: "Forza Edition",
    Price: 305000,
    Class: "B",
    PI: 613,
  },
  {
    Year: 1973,
    Manufacturer: "Ford",
    Model: "XB Falcon GT",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 60000,
    Class: "C",
    PI: 529,
  },
  {
    Year: 1973,
    Manufacturer: "Ford",
    Model: "Capri RS3100",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 55000,
    Class: "D",
    PI: 478,
  },
  {
    Year: 1973,
    Manufacturer: "Ford",
    Model: "Escort RS1600",
    Type: VehicleType.ClassicRally,
    Boost: "",
    Rarity: "Rare",
    Price: 73000,
    Class: "D",
    PI: 440,
  },
  {
    Year: 1972,
    Manufacturer: "Ford",
    Model: "Falcon XA GT-HO",
    Type: VehicleType.ClassicMuscle,
    Boost: "Speed Skills",
    Rarity: "Forza Edition",
    Price: 330000,
    Class: "S1",
    PI: 811,
  },
  {
    Year: 1972,
    Manufacturer: "Ford",
    Model: "Falcon XA GT-HO",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 80000,
    Class: "B",
    PI: 608,
  },
  {
    Year: 1971,
    Manufacturer: "Ford",
    Model: "Mustang Mach 1",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 45000,
    Class: "C",
    PI: 561,
  },
  {
    Year: 1970,
    Manufacturer: "Ford",
    Model: "GT70",
    Type: VehicleType.ClassicRally,
    Boost: "",
    Rarity: "Legendary",
    Price: 230000,
    Class: "B",
    PI: 694,
  },
  {
    Year: 1969,
    Manufacturer: "Ford",
    Model: "Mustang Boss 302",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Legendary",
    Price: 230000,
    Class: "C",
    PI: 564,
  },
  {
    Year: 1968,
    Manufacturer: "Ford",
    Model: "Mustang GT 2+2 Fastback",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 50000,
    Class: "C",
    PI: 505,
  },
  {
    Year: 1966,
    Manufacturer: "Ford",
    Model: "#2 GT40 MkII (Le Mans)",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "A",
    PI: 795,
  },
  {
    Year: 1966,
    Manufacturer: "Ford",
    Model: "Lotus Cortina",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "D",
    PI: 379,
  },
  {
    Year: 1965,
    Manufacturer: "Ford",
    Model: "Mustang GT Coupe",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 46000,
    Class: "C",
    PI: 507,
  },
  {
    Year: 1965,
    Manufacturer: "Ford",
    Model: "Transit",
    Type: VehicleType.VansAndUtility,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1964,
    Manufacturer: "Ford",
    Model: "GT40 Mk I",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 9000000,
    Class: "A",
    PI: 772,
  },
  {
    Year: 1959,
    Manufacturer: "Ford",
    Model: "Anglia 105E",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1956,
    Manufacturer: "Ford",
    Model: "F-100",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Common",
    Price: 36000,
    Class: "D",
    PI: 221,
  },
  {
    Year: 1946,
    Manufacturer: "Ford",
    Model: "Super Deluxe Station Wagon",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Rare",
    Price: 75000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1940,
    Manufacturer: "Ford",
    Model: "De Luxe Coupe",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Common",
    Price: 44000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1932,
    Manufacturer: "Ford",
    Model: 'Custom "Double Down"',
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Rare",
    Price: 105000,
    Class: "S2",
    PI: 927,
  },
  {
    Year: 1932,
    Manufacturer: "Ford",
    Model: 'Roadster "Hula Girl"',
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Epic",
    Price: 105000,
    Class: "C",
    PI: 590,
  },
  {
    Year: 1932,
    Manufacturer: "Ford",
    Model: "De Luxe Five-Window Coupe",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 2018,
    Manufacturer: "Formula Drift",
    Model: "#64 Nissan 370Z",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S1",
    PI: 884,
  },
  {
    Year: 2017,
    Manufacturer: "Formula Drift",
    Model: "#117 599 GTB Fiorano",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "S1",
    PI: 896,
  },
  {
    Year: 2016,
    Manufacturer: "Formula Drift",
    Model: "#530 HSV Maloo Gen-F",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "S1",
    PI: 836,
  },
  {
    Year: 2015,
    Manufacturer: "Formula Drift",
    Model: "#13 Ford Mustang",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "S1",
    PI: 852,
  },
  {
    Year: 2013,
    Manufacturer: "Formula Drift",
    Model: "#777 Chevrolet Corvette",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S1",
    PI: 885,
  },
  {
    Year: 2006,
    Manufacturer: "Formula Drift",
    Model: "#43 Dodge Viper SRT10",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "S1",
    PI: 859,
  },
  {
    Year: 1997,
    Manufacturer: "Formula Drift",
    Model: "#777 Nissan 240SX",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "S1",
    PI: 861,
  },
  {
    Year: 1996,
    Manufacturer: "Formula Drift",
    Model: "#232 Nissan 240SX",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "S1",
    PI: 859,
  },
  {
    Year: 1995,
    Manufacturer: "Formula Drift",
    Model: "#118 Nissan 240SX",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "S1",
    PI: 860,
  },
  {
    Year: 1989,
    Manufacturer: "Formula Drift",
    Model: "#98 BMW 325i",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "S1",
    PI: 837,
  },
  {
    Year: 2018,
    Manufacturer: "Funco Motorsports",
    Model: "F9",
    Type: VehicleType.OffroadBuggies,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 860,
  },
  {
    Year: 1992,
    Manufacturer: "GMC",
    Model: "Typhoon",
    Type: VehicleType.VansAndUtility,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "C",
    PI: 510,
  },
  {
    Year: 1991,
    Manufacturer: "GMC",
    Model: "Syclone",
    Type: VehicleType.VansAndUtility,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "C",
    PI: 588,
  },
  {
    Year: 1983,
    Manufacturer: "GMC",
    Model: "Vandura G-1500",
    Type: VehicleType.VansAndUtility,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 151,
  },
  {
    Year: 1985,
    Manufacturer: "HDT",
    Model: "VK Commodore Group A",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 28000,
    Class: "C",
    PI: 545,
  },
  {
    Year: 2019,
    Manufacturer: "Hennessey",
    Model: "VelociRaptor 6x6",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 350000,
    Class: "A",
    PI: 705,
  },
  {
    Year: 2012,
    Manufacturer: "Hennessey",
    Model: "Venom GT",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1200000,
    Class: "S2",
    PI: 930,
  },
  {
    Year: 1966,
    Manufacturer: "Hillman",
    Model: "Imp",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1988,
    Manufacturer: "Holden",
    Model: "VL Commodore Group A SV",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "C",
    PI: 578,
  },
  {
    Year: 1977,
    Manufacturer: "Holden",
    Model: "Torana A9X",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 130000,
    Class: "C",
    PI: 507,
  },
  {
    Year: 1974,
    Manufacturer: "Holden",
    Model: "Sandman HQ panel van",
    Type: VehicleType.VansAndUtility,
    Boost: "",
    Rarity: "Rare",
    Price: 55000,
    Class: "D",
    PI: 500,
  },
  {
    Year: 1973,
    Manufacturer: "Holden",
    Model: "HQ Monaro GTS 350",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 75000,
    Class: "C",
    PI: 524,
  },
  {
    Year: 2018,
    Manufacturer: "Honda",
    Model: "Civic Type R",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 59000,
    Class: "A",
    PI: 745,
  },
  {
    Year: 2016,
    Manufacturer: "Honda",
    Model: "Civic Coupe GRC",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Common",
    Price: 155000,
    Class: "S1",
    PI: 878,
  },
  {
    Year: 2015,
    Manufacturer: "Honda",
    Model: "Ridgeline Baja Trophy Truck",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Rare",
    Price: 500000,
    Class: "A",
    PI: 751,
  },
  {
    Year: 2015,
    Manufacturer: "Honda",
    Model: "Civic Type R",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 38000,
    Class: "A",
    PI: 714,
  },
  {
    Year: 2009,
    Manufacturer: "Honda",
    Model: "S2000 CR",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "B",
    PI: 647,
  },
  {
    Year: 2007,
    Manufacturer: "Honda",
    Model: "Civic Type-R",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "C",
    PI: 576,
  },
  {
    Year: 2005,
    Manufacturer: "Honda",
    Model: "NSX-R GT",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "A",
    PI: 744,
  },
  {
    Year: 2005,
    Manufacturer: "Honda",
    Model: "NSX-R",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 150000,
    Class: "A",
    PI: 714,
  },
  {
    Year: 2004,
    Manufacturer: "Honda",
    Model: "Civic Type-R",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "B",
    PI: 610,
  },
  {
    Year: 2003,
    Manufacturer: "Honda",
    Model: "S2000",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "B",
    PI: 618,
  },
  {
    Year: 1997,
    Manufacturer: "Honda",
    Model: "Civic Type R",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 551,
  },
  {
    Year: 1994,
    Manufacturer: "Honda",
    Model: "Prelude Si",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "D",
    PI: 500,
  },
  {
    Year: 1992,
    Manufacturer: "Honda",
    Model: "NSX-R",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 90000,
    Class: "A",
    PI: 707,
  },
  {
    Year: 1991,
    Manufacturer: "Honda",
    Model: "CR-X SiR",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "C",
    PI: 504,
  },
  {
    Year: 1984,
    Manufacturer: "Honda",
    Model: "Civic CRX Mugen",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 40000,
    Class: "D",
    PI: 492,
  },
  {
    Year: 1974,
    Manufacturer: "Honda",
    Model: "Civic RS",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 320,
  },
  {
    Year: 1970,
    Manufacturer: "Honda",
    Model: "S800",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "D",
    PI: 193,
  },
  {
    Year: 2017,
    Manufacturer: "Hoonigan",
    Model: "Gymkhana 10 Ford Fiesta ST",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 828,
  },
  {
    Year: 2016,
    Manufacturer: "Hoonigan",
    Model: 'Gymkhana 9 Ford Focus RS RX "Welcome Pack"',
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 2016,
    Manufacturer: "Hoonigan",
    Model: "Gymkhana 9 Ford Focus RS RX",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 886,
  },
  {
    Year: 2016,
    Manufacturer: "Hoonigan",
    Model: "Gymkhana 10 Ford Focus RS RX",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 886,
  },
  {
    Year: 2015,
    Manufacturer: "Hoonigan",
    Model: "Gymkhana 8 Ford Fiesta ST RX43",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 885,
  },
  {
    Year: 1992,
    Manufacturer: "Hoonigan",
    Model: "Mazda RX-7 Twerkstallion",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 50000,
    Class: "S1",
    PI: 838,
  },
  {
    Year: 1991,
    Manufacturer: "Hoonigan",
    Model: "Gymkhana 10 Ford Escort Cosworth Group A",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Common",
    Price: 300000,
    Class: "S1",
    PI: 880,
  },
  {
    Year: 1991,
    Manufacturer: "Hoonigan",
    Model: "Rauh-Welt Begriff Porsche 911 Turbo",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 160000,
    Class: "S1",
    PI: 850,
  },
  {
    Year: 1986,
    Manufacturer: "Hoonigan",
    Model: "Ford RS200 Evolution",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S2",
    PI: 932,
  },
  {
    Year: 1978,
    Manufacturer: "Hoonigan",
    Model: "Ford Escort RS1800",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Epic",
    Price: 300000,
    Class: "S1",
    PI: 828,
  },
  {
    Year: 1977,
    Manufacturer: "Hoonigan",
    Model: 'Gymkhana 10 Ford F-150 "Hoonitruck"',
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Common",
    Price: 500000,
    Class: "S2",
    PI: 916,
  },
  {
    Year: 1974,
    Manufacturer: "Hoonigan",
    Model: "Ford Bronco",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Epic",
    Price: 200000,
    Class: "A",
    PI: 721,
  },
  {
    Year: 1972,
    Manufacturer: "Hoonigan",
    Model: "Chevrolet Napalm Nova",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "A",
    PI: 765,
  },
  {
    Year: 1965,
    Manufacturer: "Hoonigan",
    Model: 'Ford "Hoonicorn" Mustang',
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S2",
    PI: 985,
  },
  {
    Year: 1965,
    Manufacturer: "Hoonigan",
    Model: "Gymkhana 10 Ford Hoonicorn Mustang",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S2",
    PI: 985,
  },
  {
    Year: 1955,
    Manufacturer: "Hoonigan",
    Model: "Chevrolet Bel Air",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Common",
    Price: 76000,
    Class: "D",
    PI: 571,
  },
  {
    Year: 2018,
    Manufacturer: "Hot Wheels",
    Model: "2JetZ",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Legendary",
    Price: 100000,
    Class: "S1",
    PI: 889,
  },
  {
    Year: 1972,
    Manufacturer: "Hot Wheels",
    Model: "Chevrolet LUV",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Legendary",
    Price: 100000,
    Class: "B",
    PI: 663,
  },
  {
    Year: 1969,
    Manufacturer: "Hot Wheels",
    Model: "International-Harvester Loadstar C0-1600",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Legendary",
    Price: 100000,
    Class: "C",
    PI: 563,
  },
  {
    Year: 1957,
    Manufacturer: "Hot Wheels",
    Model: "Nash Metropolitan Custom",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Legendary",
    Price: 100000,
    Class: "C",
    PI: 559,
  },
  {
    Year: 1957,
    Manufacturer: "Hot Wheels",
    Model: 'Studebaker Golden Hawk "Dream Roadster"',
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Legendary",
    Price: 100000,
    Class: "A",
    PI: 701,
  },
  {
    Year: 1949,
    Manufacturer: "Hot Wheels",
    Model: "Ford F-5 Dually Custom Hot Rod",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Legendary",
    Price: 100000,
    Class: "B",
    PI: 634,
  },
  {
    Year: 2012,
    Manufacturer: "Hot Wheels",
    Model: "Rip Rod",
    Type: VehicleType.OffroadBuggies,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "A",
    PI: 747,
  },
  {
    Year: 2011,
    Manufacturer: "Hot Wheels",
    Model: "Bone Shaker",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Common",
    Price: 150000,
    Class: "A",
    PI: 795,
  },
  {
    Year: 2005,
    Manufacturer: "Hot Wheels",
    Model: "Ford Mustang",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "S1",
    PI: 841,
  },
  {
    Year: 1969,
    Manufacturer: "Hot Wheels",
    Model: "Twin Mill",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Common",
    Price: 110000,
    Class: "S1",
    PI: 821,
  },
  {
    Year: 2014,
    Manufacturer: "HSV",
    Model: "Limited Edition Gen-F GTS Maloo",
    Type: VehicleType.VansAndUtility,
    Boost: "",
    Rarity: "Rare",
    Price: 62000,
    Class: "A",
    PI: 764,
  },
  {
    Year: 2014,
    Manufacturer: "HSV",
    Model: "GEN-F GTS",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 75000,
    Class: "A",
    PI: 747,
  },
  {
    Year: 2011,
    Manufacturer: "HSV",
    Model: "GTS",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "A",
    PI: 713,
  },
  {
    Year: 1996,
    Manufacturer: "HSV",
    Model: "GTSR",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "B",
    PI: 669,
  },
  {
    Year: 1952,
    Manufacturer: "Hudson",
    Model: "Hornet",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Rare",
    Price: 66000,
    Class: "D",
    PI: 305,
  },
  {
    Year: 2006,
    Manufacturer: "Hummer",
    Model: "H1 Alpha",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Epic",
    Price: 112000,
    Class: "D",
    PI: 391,
  },
  {
    Year: 2006,
    Manufacturer: "Hummer",
    Model: "H1 Alpha Open Top",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "D",
    PI: 382,
  },
  {
    Year: 2019,
    Manufacturer: "Hyundai",
    Model: "Veloster N",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 30000,
    Class: "B",
    PI: 657,
  },
  {
    Year: 2015,
    Manufacturer: "Infiniti",
    Model: "Q60 Concept",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Common",
    Price: 50000,
    Class: "A",
    PI: 757,
  },
  {
    Year: 2014,
    Manufacturer: "Infiniti",
    Model: "Q50 Eau Rouge",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 100000,
    Class: "A",
    PI: 783,
  },
  {
    Year: 1970,
    Manufacturer: "International",
    Model: "Scout 800A",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Common",
    Price: 40000,
    Class: "D",
    PI: 334,
  },
  {
    Year: 2018,
    Manufacturer: "Italdesign",
    Model: "Zerouno",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S1",
    PI: 893,
  },
  {
    Year: 2018,
    Manufacturer: "Jaguar",
    Model: "I-PACE",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "A",
    PI: 706,
  },
  {
    Year: 2017,
    Manufacturer: "Jaguar",
    Model: "F-PACE S",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Common",
    Price: 55000,
    Class: "B",
    PI: 698,
  },
  {
    Year: 2016,
    Manufacturer: "Jaguar",
    Model: "F-TYPE Project 7",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 190000,
    Class: "S1",
    PI: 805,
  },
  {
    Year: 2015,
    Manufacturer: "Jaguar",
    Model: "XKR-S GT",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 190000,
    Class: "A",
    PI: 798,
  },
  {
    Year: 2015,
    Manufacturer: "Jaguar",
    Model: "F-TYPE R Coupe",
    Type: VehicleType.GTCars,
    Boost: "",
    Rarity: "Epic",
    Price: 110000,
    Class: "A",
    PI: 795,
  },
  {
    Year: 2015,
    Manufacturer: "Jaguar",
    Model: "XFR-S",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 110000,
    Class: "A",
    PI: 755,
  },
  {
    Year: 2015,
    Manufacturer: "Jaguar",
    Model: "XE-S",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 57000,
    Class: "B",
    PI: 660,
  },
  {
    Year: 2012,
    Manufacturer: "Jaguar",
    Model: "XKR-S",
    Type: VehicleType.GTCars,
    Boost: "",
    Rarity: "Epic",
    Price: 100000,
    Class: "A",
    PI: 782,
  },
  {
    Year: 1993,
    Manufacturer: "Jaguar",
    Model: "XJ220",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 350000,
    Class: "S1",
    PI: 811,
  },
  {
    Year: 1990,
    Manufacturer: "Jaguar",
    Model: "XJ-S",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 557,
  },
  {
    Year: 1964,
    Manufacturer: "Jaguar",
    Model: "Lightweight E-Type",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "A",
    PI: 707,
  },
  {
    Year: 1961,
    Manufacturer: "Jaguar",
    Model: "E-type S1",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 150000,
    Class: "C",
    PI: 539,
  },
  {
    Year: 1959,
    Manufacturer: "Jaguar",
    Model: "Mk II 3.8",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Common",
    Price: 80000,
    Class: "D",
    PI: 414,
  },
  {
    Year: 1956,
    Manufacturer: "Jaguar",
    Model: "D-Type",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "C",
    PI: 513,
  },
  {
    Year: 1954,
    Manufacturer: "Jaguar",
    Model: "XK120 SE",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Epic",
    Price: 120000,
    Class: "D",
    PI: 469,
  },
  {
    Year: 1953,
    Manufacturer: "Jaguar",
    Model: "C-Type",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 5000000,
    Class: "D",
    PI: 495,
  },
  {
    Year: 2015,
    Manufacturer: "James Bond Edition",
    Model: "Aston Martin DB10",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 220000,
    Class: "S1",
    PI: 814,
  },
  {
    Year: 2010,
    Manufacturer: "James Bond Edition",
    Model: "Jaguar C-X75",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 1500000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 2008,
    Manufacturer: "James Bond Edition",
    Model: "Aston Martin DBS",
    Type: VehicleType.GTCars,
    Boost: "",
    Rarity: "Epic",
    Price: 325000,
    Class: "A",
    PI: 779,
  },
  {
    Year: 1999,
    Manufacturer: "James Bond Edition",
    Model: "BMW Z8",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 150000,
    Class: "A",
    PI: 720,
  },
  {
    Year: 1986,
    Manufacturer: "James Bond Edition",
    Model: "Aston Martin V8",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 200000,
    Class: "B",
    PI: 620,
  },
  {
    Year: 1981,
    Manufacturer: "James Bond Edition",
    Model: "Citroen 2CV6",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Rare",
    Price: 80000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1977,
    Manufacturer: "James Bond Edition",
    Model: "Lotus Esprit S1",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 550000,
    Class: "C",
    PI: 541,
  },
  {
    Year: 1974,
    Manufacturer: "James Bond Edition",
    Model: "AMC Hornet X Hatchback",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "D",
    PI: 414,
  },
  {
    Year: 1969,
    Manufacturer: "James Bond Edition",
    Model: "Aston Martin DBS",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 650000,
    Class: "B",
    PI: 612,
  },
  {
    Year: 1964,
    Manufacturer: "James Bond Edition",
    Model: "Aston Martin DB5",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 650000,
    Class: "B",
    PI: 618,
  },
  {
    Year: 2018,
    Manufacturer: "Jeep",
    Model: "Grand Cherokee Trackhawk",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Rare",
    Price: 73000,
    Class: "A",
    PI: 780,
  },
  {
    Year: 2016,
    Manufacturer: "Jeep",
    Model: "Trailcat",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Rare",
    Price: 75000,
    Class: "A",
    PI: 790,
  },
  {
    Year: 2014,
    Manufacturer: "Jeep",
    Model: "Grand Cherokee SRT",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Rare",
    Price: 80000,
    Class: "A",
    PI: 711,
  },
  {
    Year: 2013,
    Manufacturer: "Jeep",
    Model: "Wrangler Unlimited DeBerti Design",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "A",
    PI: 796,
  },
  {
    Year: 2012,
    Manufacturer: "Jeep",
    Model: "Wrangler Rubicon",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Common",
    Price: 50000,
    Class: "D",
    PI: 488,
  },
  {
    Year: 1976,
    Manufacturer: "Jeep",
    Model: 'CJ5 Renegade "Welcome Pack"',
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 60000,
    Class: "B",
    PI: 700,
  },
  {
    Year: 1976,
    Manufacturer: "Jeep",
    Model: "CJ5 Renegade",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Rare",
    Price: 60000,
    Class: "D",
    PI: 417,
  },
  {
    Year: 2018,
    Manufacturer: "Kia",
    Model: "Stinger",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Common",
    Price: 46000,
    Class: "A",
    PI: 715,
  },
  {
    Year: 2020,
    Manufacturer: "Koenigsegg",
    Model: "Jesko",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 2800000,
    Class: "S2",
    PI: 998,
  },
  {
    Year: 2017,
    Manufacturer: "Koenigsegg",
    Model: "Agera RS",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2000000,
    Class: "S2",
    PI: 996,
  },
  {
    Year: 2016,
    Manufacturer: "Koenigsegg",
    Model: "Regera",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1900000,
    Class: "S2",
    PI: 972,
  },
  {
    Year: 2015,
    Manufacturer: "Koenigsegg",
    Model: "One:1",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2850000,
    Class: "S2",
    PI: 993,
  },
  {
    Year: 2011,
    Manufacturer: "Koenigsegg",
    Model: "Agera",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1500000,
    Class: "S2",
    PI: 920,
  },
  {
    Year: 2008,
    Manufacturer: "Koenigsegg",
    Model: "CCGT",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 400000,
    Class: "S2",
    PI: 986,
  },
  {
    Year: 2006,
    Manufacturer: "Koenigsegg",
    Model: "CCX",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "S1",
    PI: 881,
  },
  {
    Year: 2002,
    Manufacturer: "Koenigsegg",
    Model: "CC8S",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 320000,
    Class: "S1",
    PI: 883,
  },
  {
    Year: 2018,
    Manufacturer: "KTM",
    Model: "X-Bow GT4",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 400000,
    Class: "S1",
    PI: 861,
  },
  {
    Year: 2013,
    Manufacturer: "KTM",
    Model: "X-Bow R",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 105000,
    Class: "S1",
    PI: 819,
  },
  {
    Year: 2019,
    Manufacturer: "Lamborghini",
    Model: "Urus",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Legendary",
    Price: 150000,
    Class: "A",
    PI: 795,
  },
  {
    Year: 2018,
    Manufacturer: "Lamborghini",
    Model: "Huracan Performante",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 2016,
    Manufacturer: "Lamborghini",
    Model: "Centenario LP 770-4",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2300000,
    Class: "S2",
    PI: 918,
  },
  {
    Year: 2016,
    Manufacturer: "Lamborghini",
    Model: "Aventador Superveloce",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Epic",
    Price: 480000,
    Class: "S2",
    PI: 906,
  },
  {
    Year: 2014,
    Manufacturer: "Lamborghini",
    Model: "Huracan LP 610-4",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 240000,
    Class: "S1",
    PI: 866,
  },
  {
    Year: 2014,
    Manufacturer: "Lamborghini",
    Model: "Urus concept",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Legendary",
    Price: 230000,
    Class: "A",
    PI: 774,
  },
  {
    Year: 2013,
    Manufacturer: "Lamborghini",
    Model: "Veneno",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 4500000,
    Class: "S2",
    PI: 943,
  },
  {
    Year: 2012,
    Manufacturer: "Lamborghini",
    Model: 'Aventador LP700-4 "Welcome Pack"',
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 310000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 2012,
    Manufacturer: "Lamborghini",
    Model: "Aventador LP700-4",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Epic",
    Price: 310000,
    Class: "S1",
    PI: 882,
  },
  {
    Year: 2012,
    Manufacturer: "Lamborghini",
    Model: "Aventador LP700-4",
    Type: VehicleType.Hypercars,
    Boost: "Speed Skills",
    Rarity: "Forza Edition",
    Price: 560000,
    Class: "S1",
    PI: 879,
  },
  {
    Year: 2012,
    Manufacturer: "Lamborghini",
    Model: "Aventador J",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2700000,
    Class: "S1",
    PI: 871,
  },
  {
    Year: 2012,
    Manufacturer: "Lamborghini",
    Model: "Gallardo LP 570-4 Spyder Performante",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Common",
    Price: 280000,
    Class: "S1",
    PI: 814,
  },
  {
    Year: 2011,
    Manufacturer: "Lamborghini",
    Model: "Sesto Elemento",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 2500000,
    Class: "S2",
    PI: 948,
  },
  {
    Year: 2011,
    Manufacturer: "Lamborghini",
    Model: "Gallardo LP 570-4 Superleggera",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 180000,
    Class: "S1",
    PI: 833,
  },
  {
    Year: 2010,
    Manufacturer: "Lamborghini",
    Model: "Murcielago LP 670-4 SV",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 840,
  },
  {
    Year: 2008,
    Manufacturer: "Lamborghini",
    Model: "Reventon",
    Type: VehicleType.ModernSuperCars,
    Boost: "Credits",
    Rarity: "Forza Edition",
    Price: 1625000,
    Class: "S1",
    PI: 847,
  },
  {
    Year: 2008,
    Manufacturer: "Lamborghini",
    Model: "Reventon",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1375000,
    Class: "S1",
    PI: 841,
  },
  {
    Year: 1999,
    Manufacturer: "Lamborghini",
    Model: "Diablo GTR",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "S2",
    PI: 915,
  },
  {
    Year: 1997,
    Manufacturer: "Lamborghini",
    Model: "Diablo SV",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 175000,
    Class: "A",
    PI: 787,
  },
  {
    Year: 1988,
    Manufacturer: "Lamborghini",
    Model: "Countach LP5000 QV",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 220000,
    Class: "A",
    PI: 759,
  },
  {
    Year: 1986,
    Manufacturer: "Lamborghini",
    Model: "LM 002",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Epic",
    Price: 180000,
    Class: "C",
    PI: 553,
  },
  {
    Year: 1972,
    Manufacturer: "Lamborghini",
    Model: "Jarama S",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 150000,
    Class: "C",
    PI: 559,
  },
  {
    Year: 1967,
    Manufacturer: "Lamborghini",
    Model: "Miura P400",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 1000000,
    Class: "B",
    PI: 619,
  },
  {
    Year: 1992,
    Manufacturer: "Lancia",
    Model: "Delta HF Integrale EVO",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Rare",
    Price: 60000,
    Class: "C",
    PI: 532,
  },
  {
    Year: 1986,
    Manufacturer: "Lancia",
    Model: "Delta S4",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Epic",
    Price: 146000,
    Class: "B",
    PI: 638,
  },
  {
    Year: 1982,
    Manufacturer: "Lancia",
    Model: "037 Stradale",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Epic",
    Price: 95000,
    Class: "C",
    PI: 590,
  },
  {
    Year: 1974,
    Manufacturer: "Lancia",
    Model: "Stratos HF Stradale",
    Type: VehicleType.ClassicRally,
    Boost: "",
    Rarity: "Legendary",
    Price: 550000,
    Class: "C",
    PI: 544,
  },
  {
    Year: 1968,
    Manufacturer: "Lancia",
    Model: "Fulvia Coupe Rallye 1.6 HF",
    Type: VehicleType.ClassicRally,
    Boost: "",
    Rarity: "Rare",
    Price: 60000,
    Class: "D",
    PI: 490,
  },
  {
    Year: 2018,
    Manufacturer: "Land Rover",
    Model: "Range Rover Velar First Edition",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Epic",
    Price: 85000,
    Class: "B",
    PI: 658,
  },
  {
    Year: 2015,
    Manufacturer: "Land Rover",
    Model: "Range Rover Sport SVR",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Epic",
    Price: 133000,
    Class: "A",
    PI: 752,
  },
  {
    Year: 1997,
    Manufacturer: "Land Rover",
    Model: "Defender 90",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Rare",
    Price: 30000,
    Class: "D",
    PI: 357,
  },
  {
    Year: 1973,
    Manufacturer: "Land Rover",
    Model: "Range Rover",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 50000,
    Class: "D",
    PI: 235,
  },
  {
    Year: 1972,
    Manufacturer: "Land Rover",
    Model: "Series III",
    Type: VehicleType.Offroad,
    Boost: "Destruction",
    Rarity: "Forza Edition",
    Price: 270000,
    Class: "D",
    PI: 126,
  },
  {
    Year: 1972,
    Manufacturer: "Land Rover",
    Model: "Series III",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 2019,
    Manufacturer: "LEGO Speed Champions",
    Model: "McLaren Senna",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1000000,
    Class: "S2",
    PI: 978,
  },
  {
    Year: 2019,
    Manufacturer: "LEGO Speed Champions",
    Model: "Bugatti Chiron",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2400000,
    Class: "S2",
    PI: 937,
  },
  {
    Year: 1987,
    Manufacturer: "LEGO Speed Champions",
    Model: "Ferrari F40 Competizione",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 3000000,
    Class: "S2",
    PI: 973,
  },
  {
    Year: 1974,
    Manufacturer: "LEGO Speed Champions",
    Model: "Porsche 911 Turbo 3.0",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 898,
  },
  {
    Year: 1967,
    Manufacturer: "LEGO Speed Champions",
    Model: "Mini Cooper S Rally",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 831,
  },
  {
    Year: 2015,
    Manufacturer: "Lexus",
    Model: "RC F",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "A",
    PI: 761,
  },
  {
    Year: 2010,
    Manufacturer: "Lexus",
    Model: "LFA",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "S1",
    PI: 826,
  },
  {
    Year: 2009,
    Manufacturer: "Lexus",
    Model: "IS F",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "A",
    PI: 737,
  },
  {
    Year: 2014,
    Manufacturer: "Local Motors",
    Model: "Rally Fighter",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Epic",
    Price: 100000,
    Class: "A",
    PI: 760,
  },
  {
    Year: 1969,
    Manufacturer: "Lola",
    Model: "#6 Penske Sunoco T70 MkIIIB",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 850000,
    Class: "S1",
    PI: 828,
  },
  {
    Year: 2016,
    Manufacturer: "Lotus",
    Model: "3-Eleven",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 150000,
    Class: "S1",
    PI: 870,
  },
  {
    Year: 2012,
    Manufacturer: "Lotus",
    Model: "Exige S",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 85000,
    Class: "A",
    PI: 772,
  },
  {
    Year: 2011,
    Manufacturer: "Lotus",
    Model: "Evora S",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 43000,
    Class: "A",
    PI: 748,
  },
  {
    Year: 2009,
    Manufacturer: "Lotus",
    Model: "2-Eleven",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 130000,
    Class: "S1",
    PI: 813,
  },
  {
    Year: 2005,
    Manufacturer: "Lotus",
    Model: "Elise 111S",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 45000,
    Class: "B",
    PI: 650,
  },
  {
    Year: 2002,
    Manufacturer: "Lotus",
    Model: "Esprit V8",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 42000,
    Class: "A",
    PI: 756,
  },
  {
    Year: 2000,
    Manufacturer: "Lotus",
    Model: "340R",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 40000,
    Class: "A",
    PI: 706,
  },
  {
    Year: 1999,
    Manufacturer: "Lotus",
    Model: "Elise Series 1 Sport 190",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 81000,
    Class: "A",
    PI: 728,
  },
  {
    Year: 1997,
    Manufacturer: "Lotus",
    Model: "Elise GT1",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 1800000,
    Class: "S1",
    PI: 815,
  },
  {
    Year: 1971,
    Manufacturer: "Lotus",
    Model: "Elan Sprint",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 57000,
    Class: "D",
    PI: 456,
  },
  {
    Year: 1956,
    Manufacturer: "Lotus",
    Model: "Eleven",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Rare",
    Price: 140000,
    Class: "C",
    PI: 553,
  },
  {
    Year: 2017,
    Manufacturer: "Maserati",
    Model: "Levante S",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Rare",
    Price: 85000,
    Class: "A",
    PI: 723,
  },
  {
    Year: 2014,
    Manufacturer: "Maserati",
    Model: "Ghibli S Q4",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "A",
    PI: 737,
  },
  {
    Year: 2010,
    Manufacturer: "Maserati",
    Model: "Gran Turismo S",
    Type: VehicleType.GTCars,
    Boost: "",
    Rarity: "Rare",
    Price: 156000,
    Class: "A",
    PI: 727,
  },
  {
    Year: 2008,
    Manufacturer: "Maserati",
    Model: "MC12 Versione Corsa",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 2500000,
    Class: "S2",
    PI: 993,
  },
  {
    Year: 2004,
    Manufacturer: "Maserati",
    Model: "MC12",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "Speed Skills",
    Rarity: "Forza Edition",
    Price: 1250000,
    Class: "S2",
    PI: 945,
  },
  {
    Year: 2004,
    Manufacturer: "Maserati",
    Model: "MC12",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 1000000,
    Class: "S1",
    PI: 861,
  },
  {
    Year: 1961,
    Manufacturer: "Maserati",
    Model: "Tipo 61 Birdcage",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 2400000,
    Class: "A",
    PI: 747,
  },
  {
    Year: 1957,
    Manufacturer: "Maserati",
    Model: "300 S",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 6000000,
    Class: "B",
    PI: 668,
  },
  {
    Year: 1953,
    Manufacturer: "Maserati",
    Model: "A6GCS/53 PininFarina Berlinetta",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 2000000,
    Class: "D",
    PI: 486,
  },
  {
    Year: 1939,
    Manufacturer: "Maserati",
    Model: "8CTF",
    Type: VehicleType.VintageRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "B",
    PI: 648,
  },
  {
    Year: 2016,
    Manufacturer: "Mazda",
    Model: "MX-5",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "B",
    PI: 625,
  },
  {
    Year: 2013,
    Manufacturer: "Mazda",
    Model: "MX-5",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 26000,
    Class: "C",
    PI: 525,
  },
  {
    Year: 2011,
    Manufacturer: "Mazda",
    Model: "RX-8 R3",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 27000,
    Class: "B",
    PI: 638,
  },
  {
    Year: 2005,
    Manufacturer: "Mazda",
    Model: "Mazdaspeed MX-5",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "B",
    PI: 605,
  },
  {
    Year: 2002,
    Manufacturer: "Mazda",
    Model: "RX-7 Spirit R Type-A",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "A",
    PI: 711,
  },
  {
    Year: 1997,
    Manufacturer: "Mazda",
    Model: "RX-7",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "B",
    PI: 681,
  },
  {
    Year: 1994,
    Manufacturer: "Mazda",
    Model: "MX-5 Miata",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 428,
  },
  {
    Year: 1990,
    Manufacturer: "Mazda",
    Model: "Savanna RX-7",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 561,
  },
  {
    Year: 1985,
    Manufacturer: "Mazda",
    Model: "RX-7 GSL-SE",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 250000,
    Class: "D",
    PI: 427,
  },
  {
    Year: 2019,
    Manufacturer: "McLaren",
    Model: "Speedtail",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2250000,
    Class: "S2",
    PI: 940,
  },
  {
    Year: 2019,
    Manufacturer: "McLaren",
    Model: "720S Spider",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S2",
    PI: 914,
  },
  {
    Year: 2018,
    Manufacturer: "McLaren",
    Model: "720S Coupe Preorder Car",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 340000,
    Class: "S2",
    PI: 998,
  },
  {
    Year: 2018,
    Manufacturer: "McLaren",
    Model: "Senna",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1000000,
    Class: "S2",
    PI: 977,
  },
  {
    Year: 2018,
    Manufacturer: "McLaren",
    Model: "720S Coupe",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 340000,
    Class: "S2",
    PI: 929,
  },
  {
    Year: 2018,
    Manufacturer: "McLaren",
    Model: "600LT Coupe",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "S1",
    PI: 890,
  },
  {
    Year: 2015,
    Manufacturer: "McLaren",
    Model: "650S Coupe",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 420000,
    Class: "S1",
    PI: 877,
  },
  {
    Year: 2015,
    Manufacturer: "McLaren",
    Model: "570S Coupe",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 224000,
    Class: "S1",
    PI: 848,
  },
  {
    Year: 2014,
    Manufacturer: "McLaren",
    Model: "650S Spider",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 420000,
    Class: "S1",
    PI: 873,
  },
  {
    Year: 2013,
    Manufacturer: "McLaren",
    Model: "P1",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1350000,
    Class: "S2",
    PI: 962,
  },
  {
    Year: 2013,
    Manufacturer: "McLaren",
    Model: 'P1 "Owen\'s Edition"',
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1200000,
    Class: "S2",
    PI: 957,
  },
  {
    Year: 2011,
    Manufacturer: "McLaren",
    Model: "12C Coupe",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "S1",
    PI: 854,
  },
  {
    Year: 1997,
    Manufacturer: "McLaren",
    Model: "F1 GT",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 5200000,
    Class: "S1",
    PI: 888,
  },
  {
    Year: 1993,
    Manufacturer: "McLaren",
    Model: "F1",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2000000,
    Class: "S1",
    PI: 826,
  },
  {
    Year: 2018,
    Manufacturer: "Mercedes-AMG",
    Model: "E 63 S",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "S1",
    PI: 805,
  },
  {
    Year: 2018,
    Manufacturer: "Mercedes-AMG",
    Model: "GT 4-Door Coupe",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 105000,
    Class: "A",
    PI: 797,
  },
  {
    Year: 2017,
    Manufacturer: "Mercedes-AMG",
    Model: "GT R Preorder Car",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Epic",
    Price: 295000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 2017,
    Manufacturer: "Mercedes-AMG",
    Model: "GT R",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Epic",
    Price: 295000,
    Class: "S1",
    PI: 858,
  },
  {
    Year: 2016,
    Manufacturer: "Mercedes-AMG",
    Model: "C 63 S Coupe",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 90000,
    Class: "A",
    PI: 777,
  },
  {
    Year: 2015,
    Manufacturer: "Mercedes-AMG",
    Model: "GT S",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Rare",
    Price: 157000,
    Class: "S1",
    PI: 820,
  },
  {
    Year: 2018,
    Manufacturer: "Mercedes-Benz",
    Model: "X-Class",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Rare",
    Price: 65000,
    Class: "D",
    PI: 417,
  },
  {
    Year: 2017,
    Manufacturer: "Mercedes-Benz",
    Model: 'E 350 D 4Matic All-Terrain "Project E-AT"',
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "C",
    PI: 578,
  },
  {
    Year: 2015,
    Manufacturer: "Mercedes-Benz",
    Model: "#24 Tankpool24 Racing Truck",
    Type: VehicleType.Trucks,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "B",
    PI: 682,
  },
  {
    Year: 2014,
    Manufacturer: "Mercedes-Benz",
    Model: "G 63 AMG 6x6",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "B",
    PI: 674,
  },
  {
    Year: 2014,
    Manufacturer: "Mercedes-Benz",
    Model: "Unimog U5023",
    Type: VehicleType.Trucks,
    Boost: "",
    Rarity: "Epic",
    Price: 100000,
    Class: "D",
    PI: 163,
  },
  {
    Year: 2013,
    Manufacturer: "Mercedes-Benz",
    Model: "E 63 AMG",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 105000,
    Class: "A",
    PI: 777,
  },
  {
    Year: 2013,
    Manufacturer: "Mercedes-Benz",
    Model: "A 45 AMG",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 65000,
    Class: "A",
    PI: 722,
  },
  {
    Year: 2013,
    Manufacturer: "Mercedes-Benz",
    Model: "G 65 AMG",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 261000,
    Class: "A",
    PI: 712,
  },
  {
    Year: 2012,
    Manufacturer: "Mercedes-Benz",
    Model: "C 63 AMG Coupe Black Series",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 143000,
    Class: "A",
    PI: 792,
  },
  {
    Year: 2012,
    Manufacturer: "Mercedes-Benz",
    Model: "SLK 55 AMG",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 78000,
    Class: "A",
    PI: 766,
  },
  {
    Year: 2011,
    Manufacturer: "Mercedes-Benz",
    Model: "SLS AMG",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Rare",
    Price: 200000,
    Class: "S1",
    PI: 822,
  },
  {
    Year: 2009,
    Manufacturer: "Mercedes-Benz",
    Model: "SL 65 AMG Black Series",
    Type: VehicleType.SuperGT,
    Boost: "",
    Rarity: "Legendary",
    Price: 210000,
    Class: "S1",
    PI: 820,
  },
  {
    Year: 2009,
    Manufacturer: "Mercedes-Benz",
    Model: "SL 65 AMG Black Series",
    Type: VehicleType.SuperGT,
    Boost: "Drift Skills",
    Rarity: "Forza Edition",
    Price: 460000,
    Class: "S1",
    PI: 820,
  },
  {
    Year: 1998,
    Manufacturer: "Mercedes-Benz",
    Model: "AMG CLK GTR",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2000000,
    Class: "S1",
    PI: 848,
  },
  {
    Year: 1990,
    Manufacturer: "Mercedes-Benz",
    Model: "190E 2.5-16 Evolution II",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Epic",
    Price: 150000,
    Class: "C",
    PI: 579,
  },
  {
    Year: 1987,
    Manufacturer: "Mercedes-Benz",
    Model: "AMG Hammer Coupe",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "",
    Price: 250000,
    Class: "A",
    PI: 701,
  },
  {
    Year: 1967,
    Manufacturer: "Mercedes-Benz",
    Model: "280 SL",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Epic",
    Price: 150000,
    Class: "D",
    PI: 448,
  },
  {
    Year: 1955,
    Manufacturer: "Mercedes-Benz",
    Model: "300 SLR",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 8000000,
    Class: "A",
    PI: 708,
  },
  {
    Year: 1954,
    Manufacturer: "Mercedes-Benz",
    Model: "300 SL Coupe",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 1200000,
    Class: "D",
    PI: 476,
  },
  {
    Year: 1939,
    Manufacturer: "Mercedes-Benz",
    Model: "W154",
    Type: VehicleType.VintageRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "B",
    PI: 676,
  },
  {
    Year: 1929,
    Manufacturer: "Mercedes-Benz",
    Model: "Super Sport Kurz Barker Roadster",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Epic",
    Price: 5000000,
    Class: "D",
    PI: 229,
  },
  {
    Year: 1970,
    Manufacturer: "Mercury",
    Model: "Cougar Eliminator",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "C",
    PI: 458,
  },
  {
    Year: 1949,
    Manufacturer: "Mercury",
    Model: "Coupe",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Common",
    Price: 45000,
    Class: "D",
    PI: 158,
  },
  {
    Year: 1971,
    Manufacturer: "Meyers",
    Model: "Manx",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Rare",
    Price: 35000,
    Class: "D",
    PI: 218,
  },
  {
    Year: 1986,
    Manufacturer: "MG",
    Model: "Metro 6R4",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Epic",
    Price: 125000,
    Class: "S1",
    PI: 852,
  },
  {
    Year: 1966,
    Manufacturer: "MG",
    Model: "MGB GT",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 30000,
    Class: "D",
    PI: 316,
  },
  {
    Year: 1958,
    Manufacturer: "MG",
    Model: "MGA Twin-Cam",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 40000,
    Class: "D",
    PI: 222,
  },
  {
    Year: 1938,
    Manufacturer: "MG",
    Model: "TA Midget",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 2018,
    Manufacturer: "MINI",
    Model: "X-Raid John Cooper Works Buggy",
    Type: VehicleType.OffroadBuggies,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "B",
    PI: 674,
  },
  {
    Year: 2018,
    Manufacturer: "MINI",
    Model: "John Cooper Works Convertible",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "B",
    PI: 639,
  },
  {
    Year: 2018,
    Manufacturer: "MINI",
    Model: "John Cooper Works Countryman ALL4",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "C",
    PI: 580,
  },
  {
    Year: 2013,
    Manufacturer: "MINI",
    Model: "X-Raid ALL4 Racing Countryman",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "B",
    PI: 682,
  },
  {
    Year: 2012,
    Manufacturer: "MINI",
    Model: "John Cooper Works GP",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 38000,
    Class: "B",
    PI: 642,
  },
  {
    Year: 2009,
    Manufacturer: "MINI",
    Model: "John Cooper Works",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 25000,
    Class: "B",
    PI: 639,
  },
  {
    Year: 1965,
    Manufacturer: "MINI",
    Model: "Cooper S",
    Type: VehicleType.ClassicRally,
    Boost: "",
    Rarity: "Legendary",
    Price: 30000,
    Class: "D",
    PI: 205,
  },
  {
    Year: 2008,
    Manufacturer: "Mitsubishi",
    Model: "Lancer Evolution X GSR",
    Type: VehicleType.ModernRally,
    Boost: "",
    Rarity: "Rare",
    Price: 43000,
    Class: "B",
    PI: 678,
  },
  {
    Year: 2006,
    Manufacturer: "Mitsubishi",
    Model: "Lancer Evolution IX MR",
    Type: VehicleType.ModernRally,
    Boost: "",
    Rarity: "Common",
    Price: 31000,
    Class: "B",
    PI: 649,
  },
  {
    Year: 2004,
    Manufacturer: "Mitsubishi",
    Model: "Lancer Evolution VIII MR",
    Type: VehicleType.ModernRally,
    Boost: "",
    Rarity: "Common",
    Price: 31000,
    Class: "B",
    PI: 686,
  },
  {
    Year: 1999,
    Manufacturer: "Mitsubishi",
    Model: "Lancer Evolution VI GSR",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Common",
    Price: 28000,
    Class: "B",
    PI: 675,
  },
  {
    Year: 1997,
    Manufacturer: "Mitsubishi",
    Model: "GTO",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "B",
    PI: 602,
  },
  {
    Year: 1995,
    Manufacturer: "Mitsubishi",
    Model: "Eclipse GSX",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 543,
  },
  {
    Year: 1992,
    Manufacturer: "Mitsubishi",
    Model: "Galant VR-4",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 532,
  },
  {
    Year: 1988,
    Manufacturer: "Mitsubishi",
    Model: "Starion ESI-R",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "C",
    PI: 555,
  },
  {
    Year: 2018,
    Manufacturer: "Morgan",
    Model: "Aero GT",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 160000,
    Class: "S1",
    PI: 811,
  },
  {
    Year: 2014,
    Manufacturer: "Morgan",
    Model: "3 Wheeler",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "D",
    PI: 450,
  },
  {
    Year: 2010,
    Manufacturer: "Morgan",
    Model: "Aero SuperSports",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 160000,
    Class: "A",
    PI: 766,
  },
  {
    Year: 1965,
    Manufacturer: "Morris",
    Model: "Mini-Traveller",
    Type: VehicleType.VansAndUtility,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1958,
    Manufacturer: "Morris",
    Model: "Minor 1000",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1953,
    Manufacturer: "Morris",
    Model: "Minor Series II Traveler",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Rare",
    Price: 25000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 2010,
    Manufacturer: "Mosler",
    Model: "MT900S",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 320000,
    Class: "S2",
    PI: 957,
  },
  {
    Year: 1933,
    Manufacturer: "Napier",
    Model: "Napier-Railton",
    Type: VehicleType.VintageRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 1000000,
    Class: "B",
    PI: 628,
  },
  {
    Year: 2018,
    Manufacturer: "Nissan",
    Model: "Sentra NISMO",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Common",
    Price: 24000,
    Class: "C",
    PI: 588,
  },
  {
    Year: 2017,
    Manufacturer: "Nissan",
    Model: "GT-R Preorder Car",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 132000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 2017,
    Manufacturer: "Nissan",
    Model: "GT-R",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 132000,
    Class: "S1",
    PI: 836,
  },
  {
    Year: 2016,
    Manufacturer: "Nissan",
    Model: "Titan Warrior Concept",
    Type: VehicleType.ExtremeOffroad,
    Boost: "Destruction",
    Rarity: "Forza Edition",
    Price: 300000,
    Class: "C",
    PI: 583,
  },
  {
    Year: 2016,
    Manufacturer: "Nissan",
    Model: "Titan Warrior Concept",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Common",
    Price: 50000,
    Class: "D",
    PI: 491,
  },
  {
    Year: 2012,
    Manufacturer: "Nissan",
    Model: "GT-R Black Edition",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 105000,
    Class: "S1",
    PI: 820,
  },
  {
    Year: 2010,
    Manufacturer: "Nissan",
    Model: "370Z",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 40000,
    Class: "A",
    PI: 716,
  },
  {
    Year: 2003,
    Manufacturer: "Nissan",
    Model: "Fairlady Z",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "B",
    PI: 671,
  },
  {
    Year: 2002,
    Manufacturer: "Nissan",
    Model: "Skyline GT-R V-Spec II",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 63000,
    Class: "B",
    PI: 691,
  },
  {
    Year: 2000,
    Manufacturer: "Nissan",
    Model: "Silvia Spec-R",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "B",
    PI: 643,
  },
  {
    Year: 1998,
    Manufacturer: "Nissan",
    Model: "R390",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 730000,
    Class: "S1",
    PI: 877,
  },
  {
    Year: 1998,
    Manufacturer: "Nissan",
    Model: "Silvia K's Aero",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 593,
  },
  {
    Year: 1997,
    Manufacturer: "Nissan",
    Model: "Skyline GT-R V-Spec",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 37000,
    Class: "B",
    PI: 633,
  },
  {
    Year: 1995,
    Manufacturer: "Nissan",
    Model: "NISMO GT-R LM",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "Event Influence",
    Rarity: "Forza Edition",
    Price: 1350000,
    Class: "S1",
    PI: 886,
  },
  {
    Year: 1995,
    Manufacturer: "Nissan",
    Model: "NISMO GT-R LM",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1100000,
    Class: "B",
    PI: 685,
  },
  {
    Year: 1994,
    Manufacturer: "Nissan",
    Model: "Fairlady Z Version S Twin Turbo",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 25000,
    Class: "B",
    PI: 655,
  },
  {
    Year: 1994,
    Manufacturer: "Nissan",
    Model: "Silvia K's",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 600,
  },
  {
    Year: 1993,
    Manufacturer: "Nissan",
    Model: "Skyline GT-R V-Spec",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 85000,
    Class: "B",
    PI: 620,
  },
  {
    Year: 1993,
    Manufacturer: "Nissan",
    Model: "240SX SE",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 449,
  },
  {
    Year: 1992,
    Manufacturer: "Nissan",
    Model: "Silvia CLUB K's",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 525,
  },
  {
    Year: 1990,
    Manufacturer: "Nissan",
    Model: "Pulsar GTi-R",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "C",
    PI: 598,
  },
  {
    Year: 1987,
    Manufacturer: "Nissan",
    Model: "Skyline GTS-R (R31)",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 100000,
    Class: "C",
    PI: 538,
  },
  {
    Year: 1973,
    Manufacturer: "Nissan",
    Model: "Skyline H/T 2000GT-R",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 170000,
    Class: "D",
    PI: 494,
  },
  {
    Year: 1971,
    Manufacturer: "Nissan",
    Model: "Skyline 2000GT-R",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 60000,
    Class: "D",
    PI: 455,
  },
  {
    Year: 1969,
    Manufacturer: "Nissan",
    Model: "Fairlady Z 432",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 150000,
    Class: "D",
    PI: 439,
  },
  {
    Year: 2010,
    Manufacturer: "Noble",
    Model: "M600",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Epic",
    Price: 450000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 1969,
    Manufacturer: "Oldsmobile",
    Model: "Hurst/Olds 442",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 65000,
    Class: "C",
    PI: 520,
  },
  {
    Year: 1984,
    Manufacturer: "Opel",
    Model: "Manta 400",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Epic",
    Price: 100000,
    Class: "A",
    PI: 740,
  },
  {
    Year: 1963,
    Manufacturer: "Opel",
    Model: "Kadett A",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 2016,
    Manufacturer: "Pagani",
    Model: "Huayra BC",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1500000,
    Class: "S2",
    PI: 961,
  },
  {
    Year: 2012,
    Manufacturer: "Pagani",
    Model: "Huayra",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1300000,
    Class: "S1",
    PI: 900,
  },
  {
    Year: 2010,
    Manufacturer: "Pagani",
    Model: "Zonda R",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "Clean Skills",
    Rarity: "Forza Edition",
    Price: 1950000,
    Class: "S2",
    PI: 986,
  },
  {
    Year: 2010,
    Manufacturer: "Pagani",
    Model: "Zonda R",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 1700000,
    Class: "S2",
    PI: 966,
  },
  {
    Year: 2009,
    Manufacturer: "Pagani",
    Model: "Zonda Cinque Roadster",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2100000,
    Class: "S2",
    PI: 908,
  },
  {
    Year: 1965,
    Manufacturer: "Peel",
    Model: "Trident",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 25000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1962,
    Manufacturer: "Peel",
    Model: "P50",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 20000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 2011,
    Manufacturer: "Penhall",
    Model: "The Cholla",
    Type: VehicleType.OffroadBuggies,
    Boost: "",
    Rarity: "Epic",
    Price: 100000,
    Class: "B",
    PI: 646,
  },
  {
    Year: 2007,
    Manufacturer: "Peugeot",
    Model: "207 Super 2000",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "A",
    PI: 774,
  },
  {
    Year: 1991,
    Manufacturer: "Peugeot",
    Model: "205 Rallye",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "C",
    PI: 504,
  },
  {
    Year: 1984,
    Manufacturer: "Peugeot",
    Model: "205 Turbo 16",
    Type: VehicleType.RallyMonsters,
    Boost: "Skills",
    Rarity: "Forza Edition",
    Price: 450000,
    Class: "A",
    PI: 703,
  },
  {
    Year: 1984,
    Manufacturer: "Peugeot",
    Model: "205 T16",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Legendary",
    Price: 200000,
    Class: "C",
    PI: 597,
  },
  {
    Year: 1971,
    Manufacturer: "Plymouth",
    Model: "Cuda 426 Hemi",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 160000,
    Class: "C",
    PI: 560,
  },
  {
    Year: 1970,
    Manufacturer: "Plymouth",
    Model: "Hemi Cuda Convertible Barrett-Jackson Edition",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 55000,
    Class: "B",
    PI: 689,
  },
  {
    Year: 1959,
    Manufacturer: "Plymouth",
    Model: "Atomic Punk Bubbletop",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Epic",
    Price: 105000,
    Class: "A",
    PI: 717,
  },
  {
    Year: 1958,
    Manufacturer: "Plymouth",
    Model: "Fury",
    Type: VehicleType.RodsAndCustoms,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "C",
    PI: 518,
  },
  {
    Year: 2015,
    Manufacturer: "Polaris",
    Model: "RZR XP 1000 EPS",
    Type: VehicleType.OffroadBuggies,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 464,
  },
  {
    Year: 1987,
    Manufacturer: "Pontiac",
    Model: "Firebird Trans Am GTA",
    Type: VehicleType.RetroMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 505,
  },
  {
    Year: 1977,
    Manufacturer: "Pontiac",
    Model: "Firebird Trans Am",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 45000,
    Class: "D",
    PI: 431,
  },
  {
    Year: 1973,
    Manufacturer: "Pontiac",
    Model: "Firebird Trans Am SD-455",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 61000,
    Class: "C",
    PI: 536,
  },
  {
    Year: 1969,
    Manufacturer: "Pontiac",
    Model: "GTO Judge",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Rare",
    Price: 90000,
    Class: "C",
    PI: 514,
  },
  {
    Year: 1968,
    Manufacturer: "Pontiac",
    Model: "Firebird",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "C",
    PI: 509,
  },
  {
    Year: 1965,
    Manufacturer: "Pontiac",
    Model: "GTO",
    Type: VehicleType.ClassicMuscle,
    Boost: "",
    Rarity: "Legendary",
    Price: 48000,
    Class: "C",
    PI: 514,
  },
  {
    Year: 2019,
    Manufacturer: "Porsche",
    Model: "911 GT3 RS",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 240000,
    Class: "S2",
    PI: 915,
  },
  {
    Year: 2019,
    Manufacturer: "Porsche",
    Model: "911 Carrera S",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 105000,
    Class: "S1",
    PI: 840,
  },
  {
    Year: 2019,
    Manufacturer: "Porsche",
    Model: "Macan Turbo",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "A",
    PI: 720,
  },
  {
    Year: 2018,
    Manufacturer: "Porsche",
    Model: "911 GT2 RS",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 315000,
    Class: "S2",
    PI: 910,
  },
  {
    Year: 2018,
    Manufacturer: "Porsche",
    Model: "718 Cayman GTS",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S1",
    PI: 808,
  },
  {
    Year: 2018,
    Manufacturer: "Porsche",
    Model: "Cayenne Turbo",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Legendary",
    Price: 220000,
    Class: "A",
    PI: 769,
  },
  {
    Year: 2017,
    Manufacturer: "Porsche",
    Model: "Panamera Turbo",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 150000,
    Class: "A",
    PI: 789,
  },
  {
    Year: 2016,
    Manufacturer: "Porsche",
    Model: "911 GT3 RS Preorder Car",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 235000,
    Class: "S2",
    PI: 998,
  },
  {
    Year: 2016,
    Manufacturer: "Porsche",
    Model: "911 GT3 RS",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 235000,
    Class: "S2",
    PI: 904,
  },
  {
    Year: 2016,
    Manufacturer: "Porsche",
    Model: "Cayman GT4",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 85000,
    Class: "S1",
    PI: 840,
  },
  {
    Year: 2015,
    Manufacturer: "Porsche",
    Model: "Cayman GTS",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 80000,
    Class: "A",
    PI: 791,
  },
  {
    Year: 2015,
    Manufacturer: "Porsche",
    Model: "Macan Turbo",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Epic",
    Price: 105000,
    Class: "A",
    PI: 708,
  },
  {
    Year: 2014,
    Manufacturer: "Porsche",
    Model: "918 Spyder",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Epic",
    Price: 850000,
    Class: "S2",
    PI: 942,
  },
  {
    Year: 2014,
    Manufacturer: "Porsche",
    Model: "911 Turbo S",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 150000,
    Class: "S1",
    PI: 827,
  },
  {
    Year: 2012,
    Manufacturer: "Porsche",
    Model: "911 GT2 RS",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 240000,
    Class: "S1",
    PI: 882,
  },
  {
    Year: 2012,
    Manufacturer: "Porsche",
    Model: "911 GT3 RS 4.0",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 200000,
    Class: "S1",
    PI: 847,
  },
  {
    Year: 2012,
    Manufacturer: "Porsche",
    Model: "Cayenne Turbo",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Epic",
    Price: 110000,
    Class: "A",
    PI: 740,
  },
  {
    Year: 2004,
    Manufacturer: "Porsche",
    Model: "911 GT3",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Rare",
    Price: 65000,
    Class: "S1",
    PI: 803,
  },
  {
    Year: 2003,
    Manufacturer: "Porsche",
    Model: "Carrera GT",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 400000,
    Class: "S1",
    PI: 866,
  },
  {
    Year: 1998,
    Manufacturer: "Porsche",
    Model: "911 GT1 Strassenversion",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2500000,
    Class: "S1",
    PI: 850,
  },
  {
    Year: 1995,
    Manufacturer: "Porsche",
    Model: "911 Carrera 2 by Gunther Werks",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "S1",
    PI: 823,
  },
  {
    Year: 1995,
    Manufacturer: "Porsche",
    Model: "911 GT2",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 550000,
    Class: "A",
    PI: 774,
  },
  {
    Year: 1993,
    Manufacturer: "Porsche",
    Model: "968 Turbo S",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 140000,
    Class: "A",
    PI: 716,
  },
  {
    Year: 1990,
    Manufacturer: "Porsche",
    Model: "911 Reimagined by Singer",
    Type: VehicleType.TrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 1800000,
    Class: "S1",
    PI: 855,
  },
  {
    Year: 1989,
    Manufacturer: "Porsche",
    Model: "944 Turbo",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "B",
    PI: 659,
  },
  {
    Year: 1987,
    Manufacturer: "Porsche",
    Model: "959",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 400000,
    Class: "A",
    PI: 784,
  },
  {
    Year: 1985,
    Manufacturer: "Porsche",
    Model: "#185 959 Prodrive Rally Raid",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Common",
    Price: 205000,
    Class: "A",
    PI: 779,
  },
  {
    Year: 1982,
    Manufacturer: "Porsche",
    Model: "911 Turbo 3.3 (930)",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 150000,
    Class: "B",
    PI: 688,
  },
  {
    Year: 1973,
    Manufacturer: "Porsche",
    Model: "911 Carrera RS",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Epic",
    Price: 200000,
    Class: "B",
    PI: 631,
  },
  {
    Year: 1971,
    Manufacturer: "Porsche",
    Model: "#23 917/20",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 10000000,
    Class: "S1",
    PI: 861,
  },
  {
    Year: 1970,
    Manufacturer: "Porsche",
    Model: "#3 917 LH",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Common",
    Price: 250000,
    Class: "S1",
    PI: 868,
  },
  {
    Year: 1970,
    Manufacturer: "Porsche",
    Model: "914/6",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 24000,
    Class: "D",
    PI: 408,
  },
  {
    Year: 1966,
    Manufacturer: "Porsche",
    Model: "906 Carrera 6",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "A",
    PI: 748,
  },
  {
    Year: 1964,
    Manufacturer: "Porsche",
    Model: "356 C Cabriolet Emory Special",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "B",
    PI: 687,
  },
  {
    Year: 1960,
    Manufacturer: "Porsche",
    Model: "356 RSR from Emory Motorsport",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "S1",
    PI: 846,
  },
  {
    Year: 1960,
    Manufacturer: "Porsche",
    Model: '718 RS 60 "Welcome Pack"',
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 1000000,
    Class: "A",
    PI: 800,
  },
  {
    Year: 1960,
    Manufacturer: "Porsche",
    Model: "718 RS 60",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 1000000,
    Class: "A",
    PI: 716,
  },
  {
    Year: 1959,
    Manufacturer: "Porsche",
    Model: "356 A 1600 Super",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Common",
    Price: 240000,
    Class: "D",
    PI: 180,
  },
  {
    Year: 1957,
    Manufacturer: "Porsche",
    Model: "356A Speedster",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Epic",
    Price: 300000,
    Class: "D",
    PI: 185,
  },
  {
    Year: 1955,
    Manufacturer: "Porsche",
    Model: "550A Spyder",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Epic",
    Price: 600000,
    Class: "D",
    PI: 387,
  },
  {
    Year: 1951,
    Manufacturer: "Porsche",
    Model: "#46 356 SL Gmund Coupe",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "D",
    PI: 199,
  },
  {
    Year: 2058,
    Manufacturer: "Quadra",
    Model: "Turbo-R V-Tech",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1000000,
    Class: "S1",
    PI: 890,
  },
  {
    Year: 723,
    Manufacturer: "Quartz",
    Model: "Regalia",
    Type: VehicleType.GTCars,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "B",
    PI: 675,
  },
  {
    Year: 723,
    Manufacturer: "Quartz",
    Model: "Regalia Type-D",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "C",
    PI: 597,
  },
  {
    Year: 2015,
    Manufacturer: "Radical",
    Model: "RXC Turbo",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Epic",
    Price: 330000,
    Class: "S2",
    PI: 958,
  },
  {
    Year: 2019,
    Manufacturer: "RAESR",
    Model: "Tachyon Speed",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1050000,
    Class: "S2",
    PI: 969,
  },
  {
    Year: 2017,
    Manufacturer: "Ram",
    Model: "Rebel TRX Concept",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Epic",
    Price: 100000,
    Class: "A",
    PI: 716,
  },
  {
    Year: 2017,
    Manufacturer: "Ram",
    Model: "2500 Power Wagon",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Common",
    Price: 47000,
    Class: "C",
    PI: 518,
  },
  {
    Year: 1972,
    Manufacturer: "Reliant",
    Model: "Supervan III",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Common",
    Price: 35000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 2018,
    Manufacturer: "Renault",
    Model: "Megane R.S.",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "A",
    PI: 702,
  },
  {
    Year: 2016,
    Manufacturer: "Renault",
    Model: "Clio R.S. 16 Concept",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "A",
    PI: 731,
  },
  {
    Year: 2013,
    Manufacturer: "Renault",
    Model: "Clio R.S. 200 EDC",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 29000,
    Class: "B",
    PI: 606,
  },
  {
    Year: 2010,
    Manufacturer: "Renault",
    Model: "Clio R.S.",
    Type: VehicleType.TrackToys,
    Boost: "Clean Skills",
    Rarity: "Forza Edition",
    Price: 25000,
    Class: "A",
    PI: 701,
  },
  {
    Year: 2010,
    Manufacturer: "Renault",
    Model: "Megane R.S. 250",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 30000,
    Class: "B",
    PI: 666,
  },
  {
    Year: 2010,
    Manufacturer: "Renault",
    Model: "Clio R.S.",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 598,
  },
  {
    Year: 2008,
    Manufacturer: "Renault",
    Model: "Megane R26.R",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "A",
    PI: 704,
  },
  {
    Year: 2007,
    Manufacturer: "Renault",
    Model: "Clio RS 197",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 250000,
    Class: "C",
    PI: 592,
  },
  {
    Year: 2003,
    Manufacturer: "Renault",
    Model: "Sport Clio V6",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "B",
    PI: 627,
  },
  {
    Year: 1993,
    Manufacturer: "Renault",
    Model: "Clio Williams",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 30000,
    Class: "C",
    PI: 502,
  },
  {
    Year: 1980,
    Manufacturer: "Renault",
    Model: "5 Turbo",
    Type: VehicleType.RallyMonsters,
    Boost: "Sills",
    Rarity: "Forza Edition",
    Price: 316000,
    Class: "B",
    PI: 675,
  },
  {
    Year: 1980,
    Manufacturer: "Renault",
    Model: "5 Turbo",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Rare",
    Price: 120000,
    Class: "C",
    PI: 512,
  },
  {
    Year: 1973,
    Manufacturer: "Renault",
    Model: "Alpine A110 1600s",
    Type: VehicleType.ClassicRally,
    Boost: "",
    Rarity: "Epic",
    Price: 98000,
    Class: "C",
    PI: 522,
  },
  {
    Year: 2019,
    Manufacturer: "Rimac",
    Model: "Concept Two",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 2000000,
    Class: "S2",
    PI: 994,
  },
  {
    Year: 2016,
    Manufacturer: "RJ Anderson",
    Model: "#37 Polaris RZR-Rockstar Energy Pro 2 Truck",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Epic",
    Price: 500000,
    Class: "S1",
    PI: 815,
  },
  {
    Year: 2010,
    Manufacturer: "Rossion",
    Model: "Q1",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 180000,
    Class: "S1",
    PI: 855,
  },
  {
    Year: 1984,
    Manufacturer: "Rover",
    Model: "SD1 Vitesse",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "D",
    PI: 498,
  },
  {
    Year: 2010,
    Manufacturer: "Saleen",
    Model: "S5S Raptor",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 180000,
    Class: "S1",
    PI: 873,
  },
  {
    Year: 2004,
    Manufacturer: "Saleen",
    Model: "S7",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 388000,
    Class: "S1",
    PI: 879,
  },
  {
    Year: 2012,
    Manufacturer: "Shelby",
    Model: "1000",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "A",
    PI: 782,
  },
  {
    Year: 1965,
    Manufacturer: "Shelby",
    Model: "Cobra 427 S/C",
    Type: VehicleType.RareClassics,
    Boost: "",
    Rarity: "Legendary",
    Price: 2100000,
    Class: "B",
    PI: 700,
  },
  {
    Year: 1965,
    Manufacturer: "Shelby",
    Model: "Cobra Daytona Coupe",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Legendary",
    Price: 8000000,
    Class: "B",
    PI: 645,
  },
  {
    Year: 1963,
    Manufacturer: "Shelby",
    Model: "Monaco King Cobra",
    Type: VehicleType.ClassicRacers,
    Boost: "",
    Rarity: "Epic",
    Price: 550000,
    Class: "S1",
    PI: 837,
  },
  {
    Year: 2016,
    Manufacturer: "Spania GTA",
    Model: "GTA Spano",
    Type: VehicleType.ModernSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 800000,
    Class: "S2",
    PI: 931,
  },
  {
    Year: 2016,
    Manufacturer: "Subaru",
    Model: '#199 WRX STI VT15R Rally Car "Welcome Pack"',
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "A",
    PI: 800,
  },
  {
    Year: 2016,
    Manufacturer: "Subaru",
    Model: "#199 WRX STI VT15R Rally Car",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 300000,
    Class: "A",
    PI: 762,
  },
  {
    Year: 2015,
    Manufacturer: "Subaru",
    Model: "WRX STI",
    Type: VehicleType.ModernRally,
    Boost: "",
    Rarity: "Rare",
    Price: 42000,
    Class: "B",
    PI: 693,
  },
  {
    Year: 2013,
    Manufacturer: "Subaru",
    Model: "BRZ",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 32000,
    Class: "C",
    PI: 562,
  },
  {
    Year: 2011,
    Manufacturer: "Subaru",
    Model: "WRX STI",
    Type: VehicleType.ModernRally,
    Boost: "",
    Rarity: "Common",
    Price: 33000,
    Class: "B",
    PI: 682,
  },
  {
    Year: 2008,
    Manufacturer: "Subaru",
    Model: "Impreza WRX STI",
    Type: VehicleType.ModernRally,
    Boost: "",
    Rarity: "Common",
    Price: 31000,
    Class: "B",
    PI: 681,
  },
  {
    Year: 2005,
    Manufacturer: "Subaru",
    Model: "Impreza WRX STi",
    Type: VehicleType.ModernRally,
    Boost: "",
    Rarity: "Rare",
    Price: 51000,
    Class: "B",
    PI: 694,
  },
  {
    Year: 2004,
    Manufacturer: "Subaru",
    Model: "Impreza WRX STi",
    Type: VehicleType.ModernRally,
    Boost: "",
    Rarity: "Common",
    Price: 28000,
    Class: "B",
    PI: 670,
  },
  {
    Year: 1998,
    Manufacturer: "Subaru",
    Model: "Impreza 22B STi",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Legendary",
    Price: 110000,
    Class: "B",
    PI: 650,
  },
  {
    Year: 1990,
    Manufacturer: "Subaru",
    Model: "Legacy RS",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 503,
  },
  {
    Year: 1967,
    Manufacturer: "Sunbeam",
    Model: "Tiger",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 65000,
    Class: "D",
    PI: 500,
  },
  {
    Year: 1979,
    Manufacturer: "Talbot",
    Model: "Sunbeam Lotus",
    Type: VehicleType.ClassicRally,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 469,
  },
  {
    Year: 2017,
    Manufacturer: "TAMO",
    Model: "Racemo",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 600000,
    Class: "C",
    PI: 577,
  },
  {
    Year: 2014,
    Manufacturer: "Terradyne",
    Model: "Gurkha LAPV",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 450000,
    Class: "D",
    PI: 223,
  },
  {
    Year: 2018,
    Manufacturer: "Top Gear",
    Model: "Track-Tor",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "C",
    PI: 577,
  },
  {
    Year: 2016,
    Manufacturer: "Toyota",
    Model: "Land Cruiser Arctic Trucks AT37",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Rare",
    Price: 83000,
    Class: "D",
    PI: 444,
  },
  {
    Year: 2013,
    Manufacturer: "Toyota",
    Model: "GT86",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "C",
    PI: 580,
  },
  {
    Year: 2007,
    Manufacturer: "Toyota",
    Model: "Hilux Arctic Trucks AT38",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Common",
    Price: 55000,
    Class: "C",
    PI: 516,
  },
  {
    Year: 1998,
    Manufacturer: "Toyota",
    Model: "Supra RZ",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "B",
    PI: 693,
  },
  {
    Year: 1995,
    Manufacturer: "Toyota",
    Model: "MR2 GT",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "B",
    PI: 621,
  },
  {
    Year: 1994,
    Manufacturer: "Toyota",
    Model: "Celica GT-Four ST205",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "C",
    PI: 595,
  },
  {
    Year: 1993,
    Manufacturer: "Toyota",
    Model: '#1 T100 Baja Truck "Welcome Pack"',
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "A",
    PI: 800,
  },
  {
    Year: 1993,
    Manufacturer: "Toyota",
    Model: "#1 T100 Baja Truck",
    Type: VehicleType.ExtremeOffroad,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "A",
    PI: 732,
  },
  {
    Year: 1992,
    Manufacturer: "Toyota",
    Model: "Supra 2.0 GT Twin Turbo",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "C",
    PI: 579,
  },
  {
    Year: 1992,
    Manufacturer: "Toyota",
    Model: "Celica GT-Four RC ST185",
    Type: VehicleType.RetroRally,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "C",
    PI: 577,
  },
  {
    Year: 1989,
    Manufacturer: "Toyota",
    Model: "MR2 SC",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "D",
    PI: 481,
  },
  {
    Year: 1985,
    Manufacturer: "Toyota",
    Model: "Sprinter Trueno GT Apex",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "D",
    PI: 444,
  },
  {
    Year: 1974,
    Manufacturer: "Toyota",
    Model: "Celica GT",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "D",
    PI: 312,
  },
  {
    Year: 1974,
    Manufacturer: "Toyota",
    Model: "Corolla SR5",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "D",
    PI: 290,
  },
  {
    Year: 1969,
    Manufacturer: "Toyota",
    Model: "2000GT",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 750000,
    Class: "D",
    PI: 447,
  },
  {
    Year: 1979,
    Manufacturer: "Triumph",
    Model: "TR7 Roadster",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "D",
    PI: 245,
  },
  {
    Year: 1970,
    Manufacturer: "Triumph",
    Model: "TR6 PI",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 419,
  },
  {
    Year: 1962,
    Manufacturer: "Triumph",
    Model: "TR3B",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 243,
  },
  {
    Year: 1962,
    Manufacturer: "Triumph",
    Model: "Spitfire",
    Type: VehicleType.ClassicSportsCars,
    Boost: "",
    Rarity: "Legendary",
    Price: 20000,
    Class: "D",
    PI: 203,
  },
  {
    Year: 2018,
    Manufacturer: "TVR",
    Model: "Griffith",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 105000,
    Class: "S1",
    PI: 844,
  },
  {
    Year: 2005,
    Manufacturer: "TVR",
    Model: "Sagaris",
    Type: VehicleType.RetroSportsCars,
    Boost: "Speed Skills",
    Rarity: "Forza Edition",
    Price: 336000,
    Class: "S2",
    PI: 902,
  },
  {
    Year: 2005,
    Manufacturer: "TVR",
    Model: "Sagaris",
    Type: VehicleType.RetroSportsCars,
    Boost: "",
    Rarity: "Rare",
    Price: 86000,
    Class: "A",
    PI: 797,
  },
  {
    Year: 2001,
    Manufacturer: "TVR",
    Model: "Tuscan S",
    Type: VehicleType.RetroSuperCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "A",
    PI: 747,
  },
  {
    Year: 1998,
    Manufacturer: "TVR",
    Model: "Cerbera Speed 12",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 874,
  },
  {
    Year: 2015,
    Manufacturer: "Ultima",
    Model: "Evolution Coupe 1020",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Rare",
    Price: 130000,
    Class: "S2",
    PI: 998,
  },
  {
    Year: 2016,
    Manufacturer: "Vauxhall",
    Model: "Corsa VXR",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 28000,
    Class: "B",
    PI: 610,
  },
  {
    Year: 2012,
    Manufacturer: "Vauxhall",
    Model: "Astra VXR",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "B",
    PI: 684,
  },
  {
    Year: 2010,
    Manufacturer: "Vauxhall",
    Model: "Insignia VXR",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Common",
    Price: 42000,
    Class: "B",
    PI: 676,
  },
  {
    Year: 2009,
    Manufacturer: "Vauxhall",
    Model: "Corsa VXR",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 250000,
    Class: "C",
    PI: 600,
  },
  {
    Year: 2005,
    Manufacturer: "Vauxhall",
    Model: "Monaro VXR",
    Type: VehicleType.ModernMuscle,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "A",
    PI: 716,
  },
  {
    Year: 2004,
    Manufacturer: "Vauxhall",
    Model: "VX220 Turbo",
    Type: VehicleType.ModernSportsCars,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "A",
    PI: 717,
  },
  {
    Year: 1990,
    Manufacturer: "Vauxhall",
    Model: "Lotus Carlton",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Common",
    Price: 70000,
    Class: "B",
    PI: 665,
  },
  {
    Year: 2018,
    Manufacturer: "Volkswagen",
    Model: "#94 Volkswagen Motorsport I.D R Pikes Peak",
    Type: VehicleType.ExtremeTrackToys,
    Boost: "",
    Rarity: "Legendary",
    Price: 250000,
    Class: "S2",
    PI: 998,
  },
  {
    Year: 2017,
    Manufacturer: "Volkswagen",
    Model: "#34 Volkswagen Andretti Rallycross Beetle",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 869,
  },
  {
    Year: 2014,
    Manufacturer: "Volkswagen",
    Model: "Global Rallycross Beetle",
    Type: VehicleType.RallyMonsters,
    Boost: "",
    Rarity: "Legendary",
    Price: 500000,
    Class: "S1",
    PI: 846,
  },
  {
    Year: 2014,
    Manufacturer: "Volkswagen",
    Model: "Golf R",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "B",
    PI: 672,
  },
  {
    Year: 2011,
    Manufacturer: "Volkswagen",
    Model: "Scirocco R",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 45000,
    Class: "B",
    PI: 678,
  },
  {
    Year: 2010,
    Manufacturer: "Volkswagen",
    Model: "Golf R",
    Type: VehicleType.SuperHotHatch,
    Boost: "",
    Rarity: "Rare",
    Price: 64000,
    Class: "B",
    PI: 661,
  },
  {
    Year: 2008,
    Manufacturer: "Volkswagen",
    Model: "Touareg R50",
    Type: VehicleType.SportsUtilityHeroes,
    Boost: "",
    Rarity: "Common",
    Price: 48000,
    Class: "B",
    PI: 631,
  },
  {
    Year: 2003,
    Manufacturer: "Volkswagen",
    Model: "Golf R32",
    Type: VehicleType.HotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "B",
    PI: 630,
  },
  {
    Year: 1998,
    Manufacturer: "Volkswagen",
    Model: "Gti VR6 Mk3",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 512,
  },
  {
    Year: 1995,
    Manufacturer: "Volkswagen",
    Model: "Corrado VR6",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "C",
    PI: 535,
  },
  {
    Year: 1992,
    Manufacturer: "Volkswagen",
    Model: "Golf Gti 16v Mk2",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "D",
    PI: 454,
  },
  {
    Year: 1983,
    Manufacturer: "Volkswagen",
    Model: "Golf GTI",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "D",
    PI: 408,
  },
  {
    Year: 1981,
    Manufacturer: "Volkswagen",
    Model: "Scirocco S",
    Type: VehicleType.RetroHotHatch,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "D",
    PI: 264,
  },
  {
    Year: 1970,
    Manufacturer: "Volkswagen",
    Model: "#1107 Desert Dingo Racing Stock Bug",
    Type: VehicleType.OffroadBuggies,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "D",
    PI: 438,
  },
  {
    Year: 1969,
    Manufacturer: "Volkswagen",
    Model: "Class 5/1600 Baja Bug",
    Type: VehicleType.OffroadBuggies,
    Boost: "",
    Rarity: "Rare",
    Price: 60000,
    Class: "D",
    PI: 407,
  },
  {
    Year: 1967,
    Manufacturer: "Volkswagen",
    Model: "Type 3 1600 L (Notchback)",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "D",
    PI: 153,
  },
  {
    Year: 1967,
    Manufacturer: "Volkswagen",
    Model: "Karmann Ghia",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Epic",
    Price: 250000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1966,
    Manufacturer: "Volkswagen",
    Model: "Double Cab Pick-Up",
    Type: VehicleType.VansAndUtility,
    Boost: "",
    Rarity: "Rare",
    Price: 50000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1963,
    Manufacturer: "Volkswagen",
    Model: "Beetle",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Common",
    Price: 20000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1963,
    Manufacturer: "Volkswagen",
    Model: "Type 2 De Luxe",
    Type: VehicleType.CultCars,
    Boost: "",
    Rarity: "Rare",
    Price: 40000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 1963,
    Manufacturer: "Volkswagen",
    Model: "Type 2 De Luxe",
    Type: VehicleType.CultCars,
    Boost: "Speed Skills",
    Rarity: "Forza Edition",
    Price: 290000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 2016,
    Manufacturer: "Volvo",
    Model: "Iron Knight",
    Type: VehicleType.Trucks,
    Boost: "",
    Rarity: "Epic",
    Price: 800000,
    Class: "A",
    PI: 797,
  },
  {
    Year: 2015,
    Manufacturer: "Volvo",
    Model: "V60 Polestar",
    Type: VehicleType.SuperSaloons,
    Boost: "",
    Rarity: "Common",
    Price: 62000,
    Class: "B",
    PI: 652,
  },
  {
    Year: 1997,
    Manufacturer: "Volvo",
    Model: "850 R",
    Type: VehicleType.RetroSaloons,
    Boost: "",
    Rarity: "Common",
    Price: 25000,
    Class: "C",
    PI: 544,
  },
  {
    Year: 1983,
    Manufacturer: "Volvo",
    Model: "242 Turbo Evolution",
    Type: VehicleType.ClassicRally,
    Boost: "",
    Rarity: "Rare",
    Price: 45000,
    Class: "C",
    PI: 512,
  },
  {
    Year: 2016,
    Manufacturer: "W Motors",
    Model: "Lykan HyperSport",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 3400000,
    Class: "S2",
    PI: 907,
  },
  {
    Year: 1945,
    Manufacturer: "Willys",
    Model: "MB Jeep",
    Type: VehicleType.Offroad,
    Boost: "",
    Rarity: "Common",
    Price: 40000,
    Class: "D",
    PI: 100,
  },
  {
    Year: 2019,
    Manufacturer: "Zenvo",
    Model: "TSR-S",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1200000,
    Class: "S2",
    PI: 972,
  },
  {
    Year: 2016,
    Manufacturer: "Zenvo",
    Model: "ST1",
    Type: VehicleType.Hypercars,
    Boost: "",
    Rarity: "Legendary",
    Price: 1000000,
    Class: "S1",
    PI: 900,
  },
];
