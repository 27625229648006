export enum VehicleType {
  ClassicMuscle,
  ClassicRacers,
  ClassicRally,
  ClassicSportsCars,
  CultCars,
  ExtremeOffroad,
  ExtremeTrackToys,
  GTCars,
  HotHatch,
  Hypercars,
  ModernMuscle,
  ModernRally,
  ModernSportsCars,
  ModernSuperCars,
  Offroad,
  OffroadBuggies,
  RallyMonsters,
  RareClassics,
  RetroHotHatch,
  RetroMuscle,
  RetroRally,
  RetroSaloons,
  RetroSportsCars,
  RetroSuperCars,
  RodsAndCustoms,
  SportsUtilityHeroes,
  SuperGT,
  SuperHotHatch,
  SuperSaloons,
  TrackToys,
  Trucks,
  VansAndUtility,
  VintageRacers,
}
